.colored-button {
  padding: 8px 15px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: center;
  cursor: pointer;
}

.colored-button h3 {
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  white-space: nowrap;
  font-weight: 600;
  line-height: normal;
}
.colored-button img {
  width: 22.998px;
  height: 23px;
}
.blue_button {
  color: #fff;
  border: 0.8px solid #1b224c;
  background-color: #1b224c;
}

.red_button {
  background-color: #f3223c;
  color: #fff;
  border: 0.8px solid #f3223c;
}

.unfilled_button {
  background-color: transparent;
  color: #f3223c;
  border: 0.8px solid #f3223c;
}
.blue_button:hover {
  border: 0.8px solid #1b224c;
  background-color: white;
  transition: all 0.2s ease;
}
.blue_button:hover img {
  filter: brightness(0) saturate(100%) invert(13%) sepia(84%) saturate(584%)
    hue-rotate(194deg) brightness(92%) contrast(98%);
}
.blue_button:hover h3 {
  color: #1b224c;
}

.red_button:hover {
  border: 0.8px solid #f3223c;
  background-color: white;
  transition: all 0.2s ease;
}
.red_button:hover img {
  filter: brightness(0) saturate(100%) invert(29%) sepia(75%) saturate(5642%)
    hue-rotate(341deg) brightness(115%) contrast(110%);
}
.red_button:hover h3 {
  color: #f3223c;
}
.unfilled_button:hover {
  border: 0.8px solid #1b224c;
  background-color: transparent;
  transition: all 0.2s ease;
}
.unfilled_button:hover img {
  filter: brightness(0) saturate(100%) invert(13%) sepia(84%) saturate(584%)
    hue-rotate(194deg) brightness(92%) contrast(98%);
}
.unfilled_button:hover h3 {
  color: #1b224c;
}

@media screen and (max-width: 1390px) {
  .colored-button h3 {
    font-size: 14px;
    white-space: nowrap;
  }
  .colored-button {
    padding: 8px 8px;
  }

  .colored-button img {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 1000px) {
  /* .image {
    display: none;
  } */
  .colored-button {
    font-size: 13px;
    padding: 5px 8px;
  }
}
