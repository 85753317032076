.logs-cont {
  gap: 1rem;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.logsTimeline-cont {
  width: 40%;
}
.logsEmail-cont {
  width: 60%;
}
.history {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.logsTimeline-cont,
.logsEmail-cont {
  background-color: #fff;
  padding: 15px;
  width: 100%;
}
.email-sent-cont p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.email-sent-cont h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.email-sent-cont h4 span {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.email-sent-cont h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  line-height: normal;
}
.email-sent-cont h2 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  vertical-align: middle;
}

.email-sent-cont {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.email-sent-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.attachment-cont img,
.attachment-cont h2 {
  vertical-align: middle;
}
.email-content {
  display: flex;
  font-size: 18px !important;
  flex-direction: column;
}
.tab-singleapp-container {
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  gap: 2rem;
}
@media screen and (max-height: 700px) and (max-width: 1300px) {
  .email_container {
    padding: 15px;
    gap: 5px;
  }

  .email_container h3,
  .email_header_container p,
  .email-content p,
  .email-sent-header h2,
  .email-sent-header h4,
  .email-sent-header h4 {
    font-size: 12px;
  }

  .email_container h4 {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
   .email_container h3,
  .email_header_container p,
  .email-content p,
  .email-sent-header h2,
  .email-sent-header h4,
  .email-sent-header h4  span{
    font-size: 12px;
  }
  .email-sent-cont h4 {
    font-size: 14px;
  }
  .email-content h4 {
    font-size: 14px;
  }
  .email-content div {
    font-size: 14px;
  }
  .logs-cont {
    padding: 0px;
  }
  .logsTimeline-cont,
  .logsEmail-cont {
    padding: 8px;
  }
}
