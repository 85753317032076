.welcome-subdiv {
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media print {
  .noPrint {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .welcome-subdiv {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
