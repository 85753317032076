.emailflowContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  height: 67vh;
}
.chartCont{
  background-color: white;
}
.emailWokFlowMobile {
  display: none;
  z-index: -50;
}
p {
  color: #000;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 800px) {
  .emailDetailsContainer {
    display: none;
  }
  .emailflowContainer {
    grid-template-columns: 1fr;
  }
  .emailWokFlowMobile {
    display: block;
  }
}
