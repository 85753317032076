.rateCont {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
}

.cont {
  display: flex;
  flex-direction: column;
}
