.gridTwo {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
}
.rateCont {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
}

@media screen and (max-width: 1249px) {
  .gridTwo {
    grid-template-columns: 1fr;
  }
}
