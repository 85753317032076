.wrapping-container {
  padding: 2vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.white-container {
  width: 100%;
  /* background-color: #fff; */
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.029);
  height: 85vh;
  overflow: scroll;
  gap: 1rem;
  border-radius: 10px;
}

.white-container > h1,
.whiteBoxContainer > h1 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.white-container > h2,
.whiteBoxContainer > h2 {
  color: #1b224c;
  font-size: 18px;
  font-weight: 500;
}
.white-container > h3,
.whiteBoxContainer > h3 {
  color: #1b224c;
  font-size: 14px;
  font-weight: 500;
}
.whiteBoxContainer {
  background-color: #fff;
  padding: 16px 14px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
@media screen and (max-width: 1550px) and (max-height: 750px) {
  .whiteBoxContainer {
    gap: 0.5rem;
    padding: 10px ;
  }
}
@media screen and (max-height: 700px) and (max-width: 1200px) {
  .wrapping-container {
    height: auto;
    overflow-y: visible;
  }
}
@media screen and (max-width: 1000px) {
  .wrapping-container {
    padding: 1.2vh;
    height: auto;
  }

  .whiteBoxContainer {
    padding: 15px;
    border-radius: 5px;
  }
  .white-container {
    padding: 15px;
    gap: 0.8rem;
  }
}

@media screen and (max-width: 750px) {
  .wrapping-container {
    padding: 1.2vh;
    height: auto;
    gap: 0.5rem;
  }
  .whiteBoxContainer {
    padding: 10px;
    border-radius: 5px;
    height: auto;
  }
  .white-container {
    padding: 10px;
    gap: 0.5rem;
    height: 100vh;
  }
  .maxHeight {
    max-height: 40vh !important;
    height: auto !important;
  }
  .white-container > h1,
  .whiteBoxContainer > h1 {
    font-size: 14px;
    line-height: normal;
  }
  .white-container > h2,
  .whiteBoxContainer > h2 {
    font-size: 13px;
  }
}
