.applicant-tab {
  width: 320px;
  height: 53px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  clip-path: polygon(0% 100%, 0% 0%, 82.5% 0%, 100% 100%);
  cursor: pointer;
}
.inactive-tab:hover {
  background-color: #ffffffc8;
}

.active-tab {
  /* border: 1px solid rebeccapurple; */
  background-color: #fff;
}
.inactive-tab {
  background-color: #ffffff8d;
}
.active-tab h4 {
  color: #f3223c;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.inactive-tab h4 {
  color: #1b224c;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.circle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 25px;
  height: 25px;
  justify-content: center;
  border-radius: 50%;
  color: white;
}
.circle p {
  color: white;
  text-decoration: none;
}
.active-circle {
  background-color: #f3223c;
  /* padding: 4px; */
}

.inactive-circle {
  background-color: #a6b8d2;
}

.activeTabImage {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
@media screen and (max-width: 1390px) {
  .applicant-tab {
    height: 43px;
    width: 162px;
  }
  .active-tab h4,
  .inactive-tab h4 {
    font-size: 14px;
  }
  .applicant-tab img {
    width: 18px !important;
  }
  .inactive-circle,
  .active-circle {
    width: 18px !important;
    height: 18px !important;
    font-size: 12px;
  }
}
@media screen and (max-height: 700px) and (max-width: 1300px) {
  .applicant-tab {
    height: 43px;
    width: 162px;
  }
  .active-tab h4,
  .inactive-tab h4 {
    font-size: 14px;
  }
  .applicant-tab img {
    width: 18px !important;
  }
  .inactive-circle,
  .active-circle {
    width: 18px !important;
    height: 18px !important;
    font-size: 12px;
  }
}
@media screen and (max-width: 1000px) {
  .applicant-tab {
    height: 43px;
    width: 162px;
  }

  .active-tab h4,
  .inactive-tab h4 {
    font-size: 14px;
  }
  .applicant-tab img {
    width: 18px !important;
  }
  .inactive-circle,
  .active-circle {
    width: 22px !important;
    height: 22px !important;
    font-size: 12px;
  }
}
