.chart-div {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.chart_cont {
  background-color: #fff;
  padding: 1rem;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chart_cont h2 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media screen and (max-width:1200px){
  .chart-div{
    flex-direction: column;
  }
}
@media screen and (max-width:800px){
  .chart-div{
    flex-direction: column;
  }
  .chart_cont  h2{
    font-size: 14px;
  }
}