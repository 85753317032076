.parent {
  position: relative;
  display: flex;
}
.cont {
  display: flex;
  justify-content: center;
}
.child {
  position: absolute;
  right: 0px;
  cursor: pointer;
}
.profile {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.368);
}
