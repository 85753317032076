.gridThree {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
}
.gridThreeOfThree {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  gap: 10px;
}

@media screen and (max-height: 950px) and (max-width: 1600px) {
  .gridThree {
    grid-template-columns: 1fr 1fr;
  }
  .gridThreeOfThree {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 1400px) {
  .gridThree {
    grid-template-columns: 1fr 1fr;
  }
  .gridThreeOfThree {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 1000px) {
  .gridThree {
    grid-template-columns: 1fr;
  }
  .gridThreeOfThree {
    grid-template-columns: 1fr;
  }
}
