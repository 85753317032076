.new-email-cont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}
.newEmail-sub {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 20px;
}
.status-cont {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.status-subcont{
  display: flex;
  gap: 5px; 
  align-items: center;
}
.status-subcont img{
 height: 15px;
}
.status-cont p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.status-error {
  color: #f3223c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 1100px) {
  .new-email-cont {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 800px) {
  .newEmail-sub {
    grid-template-columns: 1fr;
  }
}
