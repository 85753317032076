.popOver-container {
  display: flex;
  flex-direction: column;
  max-height: 210px;
  overflow-y: scroll;
}
.container {
  padding: 5px;
}
.popOver-container img {
  width: 25px;
  height: 25px;
}

.popOver-subContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 13px;
  border-radius: 10px;
}

.inActiveDiv img {
  filter: brightness(0) saturate(100%) invert(72%) sepia(96%) saturate(1%)
    hue-rotate(341deg) brightness(94%) contrast(80%);
}
.inActiveDiv h4 {
  color: #bfbfbf !important;
}
.inActiveDiv img:hover {
  filter: brightness(0) saturate(100%) invert(72%) sepia(96%) saturate(1%)
    hue-rotate(341deg) brightness(94%) contrast(80%) !important;
}
.inActiveDiv h4:hover {
  color: #bfbfbf !important;
}
.popOver-subContainer-even {
  background-color: #fafafc;
}
.popOver-subContainer.inActiveDiv:hover {
  filter: brightness(0) saturate(100%) invert(72%) sepia(96%) saturate(1%)
    hue-rotate(341deg) brightness(94%) contrast(80%) !important;
  /* color: #f3223c; */
  color: #bfbfbf !important;
}
.popOver-subContainer:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
  /* color: #f3223c; */
  background-color: transparent;
}
.active {
  background-color: #ecf4ff;
}
.active img {
  filter: brightness(0) saturate(100%) invert(29%) sepia(79%) saturate(3107%)
    hue-rotate(335deg) brightness(90%) contrast(112%) !important;
}
.active h4 {
  color: #f3223c !important;
}

@media screen and (max-width: 1200px) {
  .popOver-subContainer {
    font-size: 13px;
    padding: 10px 13px;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .popOver-subContainer h4 {
    font-size: 13px;
  }
  .popOver-subContainer {
    padding: 10px 13px;
  }
  .popOver-container img {
    width: 15px;
    height: 15px;
  }
}
