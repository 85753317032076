.overview_form {
  display: flex;
  flex-direction: column;
  width: 50vw;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

@media screen and (max-width: 1200px) {
  .overview_form {
    align-items: none !important;
    width: 100%;
  }
  .form {
    grid-template-columns: 1fr;
    height: auto;
  }
}
