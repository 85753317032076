.title-page {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.title-page h2 {
  position: relative;
  color: #1b224c;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

/* .title-page h2::before {
  content: "";
  display: block;
  width: 65px;
  height: 3px;
  background: #f3223c;
  position: absolute;
  bottom: -5px; 
  left: 0;
} */


@media screen and (max-width: 950px) {
  .title-page h2 {
    font-size: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 550px) {
  .title-page h2 {
    font-size: 17px;
    text-align: center;
  }
}
