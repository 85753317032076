.horizontal-container {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  /* flex-grow: 1; */
}

.horizontal {
  width: 100%;
  height: 1.5px;
  background: transparent;
  border-top: 1px solid #d9d9d9;
}
.vertical-container {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  /* flex-grow: 1; */
}

.vertical {
  width: 0.8px;
  height: 100%;
  background: transparent;
  border-top: 2px solid #d9d9d9;
}
