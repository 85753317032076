.textBox-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.textBox-container-error {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.textBox-container label,
.selectContainer label {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 5px;
}
.icon {
  width: 35px;
  height: 25px;
  padding-left: 15px;
}

.text-input {
  background-color: transparent;
  border-radius: 5px;
  height: 40px;
  font-size: 15px;
  border: none;
  /* opacity: 0.2; */
  width: 100%;
  color: #1b224c;
  /* z-index: 1; */
  padding: 5px;
}
.icon-input-container {
  display: flex;
  gap: 0.5rem;
  border-radius: 10px;
  border: 1px solid #1b224c;
  align-items: center;
  height: 50px;
}
.icon-input-container:hover {
  border: 1px solid #1b224ca4;
}
.text-input::placeholder {
  color: #8d90a5;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-input-error {
  background-color: transparent;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #f3223c;
  /* opacity: 0.2; */
  padding: 5px;
}
/* .textBox-container input[type="text"]:hover {
  border: 0.5px solid #1b224c5c;
  transition: 0.2s ease-in-out;
} */
input[type="text"]:focus-visible,
input[type="number"]:focus-visible {
  outline: none;
  border: none;
}
input[type="password"]:focus-visible {
  outline: none;
  border: none;
}
.textBox-container input::-webkit-outer-spin-button,
.textBox-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .textBox-container input[type="number"] {
  -moz-appearance: textfield;
} */
.required {
  color: #f3223c;
  padding-left: 0px;
  font-size: 18px;
  font-weight: bold;
}

/*********Dropdown**/
.custom-dropdown-arrow {
  color: #1b224c;
  width: 12px;
  padding-right: 15px;
}

/***********Search**/
.search-bar {
  position: relative;
  border-radius: 34px;
  border: 1px solid #1b224c;
}
.search-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.search-inp {
  width: 400px;
  height: 36px;
  border: none;
  padding: 5px 10px 5px 1px;
  border-radius: 34px;
  background: #ffffff;
  color: #000;
  font-size: 20px;
}

.search-bar:hover {
  border: 1px solid #1b224ca4;
}
.search-ic {
  /* position: absolute; */
  width: 30px !important;
  /* margin-top: 6px; */
}
.exit-ic {
  position: absolute;
  width: 20px !important;
  margin-top: -8px !important;
  right: 10px;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(68%) sepia(7%) saturate(453%)
    hue-rotate(195deg) brightness(85%) contrast(90%);
}
.exit-ic:hover {
  filter: none;
}
/* .search-inp2[type="text"] {
  font-size: 13px;
  padding-left: 60px;
} */
.search-inp::placeholder {
  padding-left: 0px;
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/**********Custom Checkbox******/

.checkbox-container {
  position: relative;
  padding-left: 20px;
  margin-bottom: 0px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  border: 1.5px solid #96979e;
  background: #fbf7f7;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #f3223c;
  border: 1.5px solid #f3223c;
}

.checkbox-container:hover input ~ .checkmark {
  border: 1px solid #f3223c;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 6px;
  top: 1px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox-container p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 5px;
}

/*********Question Input**********/
.question-bar {
  /* position: relative; */
  border-radius: 12px;
  border: 1px solid #1b224c;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  align-items: center;
  /* justify-content: center; */
}
.question-bar span {
  align-self: flex-end;
}
.search-bar {
  /* position: relative; */
  border-radius: 25px;
  border: 1px solid #1b224c;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  align-items: center;
  /* justify-content: center; */
}
.question-inp {
  width: 100%;
  border: none;
  background: #ffffff;
  border-radius: 10px;
  color: #000;
  font-size: 17px;
  resize: none;
  padding-left: 5px;
}
.question-inp:hover {
  border: none;
}
.question-bar textarea {
  border: none;
  resize: none;
}
.question-bar textarea:focus {
  border: none !important;
  outline: 0 !important;
}
.question-bar textarea:focus-visible {
  border: none !important;
  outline: 0 !important;
}
.message-icon {
  width: 20px !important;
}
.message-icon-edit {
  width: 20px !important;
  padding-top: 2px;
}
.send-icon {
  width: 25px !important;
  align-self: flex-end;
}
.send-icon:hover {
  opacity: 0.8;
  cursor: pointer;
}
.img-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* .search-inp2[type="text"] {
  font-size: 13px;
  padding-left: 60px;
} */
.question-inp::placeholder {
  padding-left: 0px;
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: center !important;
}

/* SelectInput.module.css */

.selectContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}
.arrowIcon {
  /* transition: 0.4s all; */
  padding-right: 10px;
  filter: brightness(0) saturate(100%) invert(12%) sepia(24%) saturate(2954%)
    hue-rotate(206deg) brightness(99%) contrast(96%);
}
/* .openedArrow {
  transform: rotate(180deg);
  padding-left: 10px;
  transition: 0.4s all;
} */
.selectInput {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #1b224c;
  padding: 10px 5px;
  cursor: pointer;
  height: 50px;
}
.selectInput:hover {
  border: 1px solid #1b224c72;
}
.error {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #f3223c;
  padding: 10px 5px;
  cursor: pointer;
  height: 50px;
}
.selectInpuNoBorders {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 0.1px solid #80808014;
  padding: 10px 5px;
  cursor: pointer;
}

.selectInput.open {
  border-radius: 10px 10px 0 0;
  border-bottom: none;
}

.selectedValue {
  flex: 1;
}

.placeholder {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #8d90a5;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ellipsedPlaceholder {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.placeholder img {
  margin-left: 8px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.368);
  background: #fff;
  z-index: 13;
  max-height: 200px;
  overflow-y: scroll;
}
.no-scroll {
  overflow: hidden !important;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.04);
  z-index: 11;
  overflow: hidden;
}

.dropdownItem {
  padding: 15px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
}
.dropdownItem:hover {
  background: #b4b4b544;
}
.dropdownItemOdd {
  background: #fafafc;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
}
.dropdownItemOdd:hover {
  background: #b4b4b544 !important;
}
.selectedValueicont {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-content: space-between;
  width: 100%;
}
.selectedValueicont .placeholder span {
  font-size: 15px;
  font-style: normal;
  opacity: 1;
  color: #1b224c;
}
.dropdowncontainer {
  display: flex;
  flex-direction: row;
}
.dropdowncontainer p {
  padding-top: 2px;
  font-size: 15px;
  font-style: normal;
  opacity: 1;
  color: #1b224c;
}
.selectedValues {
  font-size: 15px;
}

/**********************switch*/
.switch {
  position: relative;
  display: inline-block;
  width: 32.857px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 21px;
  border: 1px solid #a6b8d2;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  background-color: #a6b8d2;
}

input:checked + .slider {
  background-color: transparent;
  border: 1px solid #f3223c;
}
input:checked + .slider:hover {
  border: 1px solid #1b224c72;
}
input:focus + .slider {
  background-color: transparent;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  border-radius: 50%;
  background-color: #f3223c;
  left: -10px;
  bottom: 2px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.toggle-container h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/*********date********/
.custom-date-icon {
  position: absolute;
  right: 10px;
  top: 14px;
  width: 20px;
  height: 20px;
  background: url("../../../assets/Imagess/calenderVector.svg") no-repeat;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
}

.custom-date-input {
  position: relative;
  border-radius: 10px;
  height: 50px;
  border: 1px solid #1b224c;
  padding: 5px;
  overflow: hidden;
}
.custom-date-input:hover {
  border: 1px solid #1b224c72;
}

.classfont-agreement {
  font-size: 17px;
}

.custom-date-input input[type="date"] {
  position: absolute;
  top: 2;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 85%;
  cursor: pointer;
  color: #1b224c;
  font-weight: 500;
  padding: 10px;
  border: transparent;
  background-color: transparent;
  font-size: 15px;
}

.custom-date-input .value input[type="date"] {
  color: #1b224c;
  opacity: 0.5;
}
.custom-date-input input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}

.custom-date-input input[type="date"]:focus-visible {
  outline: transparent;
  border: none;
}
/*************************test******************/
.custom-date-input-red {
  position: relative;
  border-radius: 10px;
  height: 50px;
  border: 1px solid #f3223c;
  padding: 5px;
  overflow: hidden;
}

/* .custom-date-input-red:hover {
  border: 0.5px solid #1b224c5c;
  transition: 0.2s ease-in-out;
} */
.custom-date-input-red input[type="date"] {
  position: absolute;
  top: 2;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 85%;
  cursor: pointer;
  color: #020a58;
  font-weight: 600;
  padding: 10px;
  border: transparent;
  background-color: transparent;
}

.custom-date-input-red input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/**Textbox**/
.textBox-container textarea,
.textBox-container-error textarea {
  resize: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
}
.textBox-container textarea::placeholder {
  color: #8d90a5;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.textBox-container textarea {
  border: 1px solid #1b224c;
}

.textBox-container-error textarea {
  border: 1px solid #f3223c;
}

.textBox-container textarea:hover {
  border: 0.5px solid #1b224c5c;
  transition: 0.2s ease-in-out;
}

.textBox-container textarea:focus,
.textBox-container textarea:focus-visible {
  border: 0.5px solid #1b224c5c !important;
  outline: 0 !important;
}
/***Search**/
.searchInput {
  border: none;
  font-size: 17px;
  width: 100%;
}

/*****Phone input******/
.mobile-container {
  display: flex;
  flex-direction: column;
}
.mobile-container label {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 5px;
}
.mobile-subcontainer {
  display: flex;
  padding: 15px;
  gap: 0.5rem;
  border-radius: 10px;
  border: 1px solid #1b224c;
  align-items: center;
  height: 50px;
}
.react-tel-input .form-control {
  color: #1b224c !important;
}
/*******radio button*/
.radio-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-input label {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.radio-input input {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* transition: .4s ease-in-out; */
  border: 2px solid #113161;
  cursor: pointer;
}
.radio-input.red input {
  border: 2px solid #f3223c;
}
.radio-input input:hover + .circle {
  /* scale: 1.2; */
  cursor: pointer;
  box-shadow: none;
}

.radio-input .circle {
  position: relative;
  top: 0em;
  right: 15px;
  width: 10px;
  height: 10px;
  background-color: #6f70705d;
  scale: 0;
  border-radius: 10px;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

.radio-input input:checked {
  box-shadow: none;
  border: 2px solid #113161;
}
.radio-input.red input:checked {
  border: 2px solid #f3223c;
}
.radio-input input:checked + .circle {
  background-color: #113161;
  scale: 0.9;
}
.radio-input.red input:checked + .circle {
  background-color: #f3223c;
}
.radio-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.radioBtn-label {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 1390px) {
  .search-inp {
    width: 300px;
  }
}
@media screen and (max-width: 1000px) {
  .search-bar {
    width: 60vw;
    padding: 0px 1px;
  }
  .search-inp {
    width: 40vw;
    height: 40px;
    font-size: 14px;
  }

  .selectContainer label {
    font-size: 13px;
  }
  .toggle-container h4 {
    font-size: 13px;
  }
  .search-inp::placeholder {
    font-size: 14px;
  }
  .placeholder {
    font-size: 13px;
  }

  .selectContainer .placeholder span,
  .dropdowncontainer {
    font-size: 13px;
  }
  .textBox-container label {
    font-size: 13px;
  }
  .dropdownItem.dropdownItemOdd {
    padding: 10px;
  }
  .selectedValues {
    font-size: 13px;
  }

  .switch {
    height: 15px;
    width: 28px;
  }
  .slider:before {
    width: 12px;
    height: 12px;
    bottom: 1px;
  }
  input:checked + .slider:before {
    bottom: 1px;
  }
  .checkbox-container p {
    font-size: 13px;
    padding-top: 2px;
  }
  .radio-input label {
    font-size: 14px !important;
  }
  .row_name {
    padding: 18px;
  }
}
@media screen and (max-width: 800px) {
  .search-bar {
    width: 50vw;
    padding: 0px 1px;
  }
  .search-inp {
    width: 40vw;
    /* height: 30px; */
  }
}
