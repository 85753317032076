.form_container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.form_container > div {
  flex: 1;
  padding: 10px;
}

.form_subcContainer{
    display: flex;
    flex-direction: column;
    gap:28px
}

@media screen and (max-width:1100px){
  .form_container{
    flex-direction: column;
  }
}