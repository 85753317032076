.tab-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.table-container {
  padding: 30px 20px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 8%);
  background-color: #fff;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  max-height: 86vh;
}

.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-top: 1px solid rgba(27, 34, 76, 0.4);
  border-right: 1px solid rgba(27, 34, 76, 0.4);
  border-left: 1px solid rgba(27, 34, 76, 0.4);
}

.circle-icon {
  padding-top: 2px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: black;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  font-weight: 500;
  background-color: #cceaff;
}

@media screen and (max-width: 1000px) {
  .table-container {
    padding: 15px 5px;
    height: 100vh;
  }
  .number {
    display: none;
  }
}
