.history {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.history h4 {
  color: #1b224c;
  font-size: 18px;
  font-weight: 700;
}
