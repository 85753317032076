.header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header_container h1 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.buttons_container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .header_container h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 800px) {
  .header_container {
    flex-wrap: wrap;
    gap:10px
  }
}
