.status {
  border-radius: 27px;
  padding: 5px 10px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  vertical-align: middle;
}

.prospect {
  background: #fff2d5;
  color: #ffb202;
}

.not-interested {
  background: #ffe3ea;
  color: #ff0000;
}

.interested {
  background: #ddf1dd;
  color: #2ba62c;
}

.converted {
  background: #d9eaff;
  color: #235594;
}
.launched {
  color: #f53baf;
  background-color: #f53bae1f;
}
.ReadyToLaunch {
  color: #f39c12;
  background-color: #f39d123b;
}

.cancelled {
  background: #ffcdcd;
  color: #e70e0e;
}

.applicant,
.Launch {
  background: #ebf7ff;
  color: #4cb1f8;
}

.allStatuses {
  background: #d94cf81f;
  color: #d94cf8;
}
.underProcess {
  background: #fff6f0;
  color: #ff6c02;
}

.onHold {
  background: #ffefef;
  color: #ea3a3d;
}

.UnSubscribed {
  background: #d0d0d0;
  color: black;
}
.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  vertical-align: middle;
}
.unSubscribedCircle {
  background-color: black;
}
.prospectCircle {
  background-color: #ffb202;
}

.not-interestedCircle {
  background-color: #ff0000;
}
.launchedCircle {
  background-color: #f53baf;
}
.interestedCircle {
  background-color: #2ba62c;
}

.convertedCircle {
  background-color: #235594;
}

.cancelledCircle {
  background-color: #e70e0e;
}

.applicantCircle,
.LaunchCircle {
  background-color: #4cb1f8;
}

.allStatusesCircle {
  background-color: #d94cf8;
}
.underProcessCircle {
  background-color: #ff6c02;
}
.onHoldCircle {
  background-color: #ea3a3d;
}
.unfilled-text {
  font-family: Raleway;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  color: #f00;
}

.unfilled-text > img {
  vertical-align: middle;
}

@media screen and (max-width: 1650px) {
  .status {
    padding: 3px 5px;
    font-size: 12px;
  }
}
@media screen and (max-width: 800px) {
  .unfilled-text > img {
    vertical-align: middle;
    width: 13px !important;
    height: 13px !important;
  }
  .unfilled-text {
    font-size: 13px !important;
  }
}
