.login-container {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: "translate(-50%,-50%";
  z-index: 1000;
  width: 100%;
  height: 100vh;
}
.login-subContainer {
  position: relative;
  padding: 20px 30px;
  background-color: #ffffff;
  border-radius: 10px;
  height: 75vh;
  width: 65vw;
  display: flex;
  flex-direction: column;
  opacity: 0.9;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.login-sub-Container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 45vw;
  overflow: scroll;
}

.login-sub-Container .logo-login {
  align-self: center;
}
.logo-login {
  width: 20vw;
}
#background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/* @media (max-height: 700px) and (min-width: 1500px) {
  .login-subContainer {
    height: 90vh;
  }
} */
.recomendation-subContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.recomendation-subContainer h2 {
  color: #000;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.recomendation-subContainer h2 span {
  color: #000;
  text-align: justify;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-cont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: center;
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.success h1 {
  color: #020a58;
  font-size: 28px;
  font-weight: 700;
}
.success img {
  height: 25vh;
}
.success p {
  color: #000;
  font-size: 24px;
  text-align: center;
}
@media (min-width: 700px) and (max-height: 600px) {
  .login-subContainer {
    height: 95vh;
  }
}
@media screen and (max-width: 750px) {
  .login-subContainer {
    width: 90vw;
    padding: 30px 20px;
    height: 95vh;
  }
  .login-sub-Container {
    width: 80vw;
  }
  .logo-login {
    width: 60vw;
  }
  .recomendation-subContainer h2 {
    font-size: 14px;
  }
  .recomendation-subContainer h2 span {
    font-size: 14px;
  }
  .success img {
    height: 20vh;
  }
  .success h1 {
    font-size: 15px;
  }
  .success p {
    font-size: 13px;
  }
}
