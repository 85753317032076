.aud-button,
.aud-button-rounded {
  border-radius: 5px;
  padding: 8px;
  color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
}

.aud-button p,
.aud-button-rounded p {
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  white-space: nowrap;
}
.aud-button:hover {
  background-color: #f3223c !important;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.aud-button-rounded:hover {
  background-color: #1b224c !important;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

/************/
.span-required {
  color: #f3223c;
  font-weight: 500;
  font-size: 15px;
}
.button-icon {
  vertical-align: middle;
  padding-right: 5px;
  height: 17px;
}
@media screen and (max-width: 1550px) and (max-height: 750px) {
  .aud-button p,
  .aud-button-rounded p {
    font-size: 15px;
  }

}
@media screen and (max-width: 1390px) {
  .aud-button,
  .aud-button-rounded {
    padding: 8px 8px;
    font-size: 15px;
  }

  .aud-button img,
  .aud-button-rounded img {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .aud-button,
  .aud-button-rounded {
    padding: 5px 8px;
    font-size: 14px;
  }

  /* .aud-button img,
  .aud-button-rounded img {
    display: none;
  } */
}
