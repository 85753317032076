.email-content-cont {
  border-radius: 10px;
  border: 1px solid #1b224c;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.email-content{
  display: flex;
  flex-direction: column;
  gap:0.5rem
}
.email-content h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 5px;
}
.email-content h4 span {
  color: #f3223c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 5px;
}
