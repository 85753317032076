.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px;
  vertical-align: middle;
}
.icon-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.email-container {
  padding: 28px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
@media screen and (max-width: 1000px) {
  /* .icon-subcontainer {
    display: grid;
    justify-items: end;
    grid-template-columns: 1fr 1fr;
  } */
  .icon-subcontainer.title-container {
    justify-content: flex-end;
  }
  .email-container {
    padding: 10px;
  }
  .email-container h2 {
    font-size: 20px;
  }
}