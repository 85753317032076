.navhead-container {
  display: flex;
  flex-direction: row;
  height: 8vh;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  z-index: 7;
  width: 100%;
  /* width:100vw;
  padding-left: 118px;
  margin-left:-113px ; */
  justify-content: space-between;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.06);
}

.navhead-img-link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userProfile {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.368);
}
.logo {
  height: 45px;
}
.backIcon {
  width: 25px;
  vertical-align: middle;
}
nav {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* overflow-y: scroll; */
}
.nav-closed {
  align-items: center;
}
.navhead-container nav a:hover {
  text-decoration: none;
  color: #f3223c;
}
.arrow {
  transition: 0.4s ease-in-out;
}
.rotate-img {
  transform: rotate(180deg);
  transition: 0.4s ease-in-out;
}
.active {
  color: #f3223c !important;
  border-radius: 50%;
}

.icon-container {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.navhead-container .userprofile {
  width: 55px;
  height: 55px;
}

.navhead-subcontainer {
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.navhead-title-arrowContainer {
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.navhead-subcontainer img:hover,
.navhead-title-arrowContainer img:hover {
  /* filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%); */
  cursor: pointer;
}
.notifications-container {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 10px;
}
.notifications-container h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 0 0 10px;
}
.notifications-container h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 0 0 10px;
}
.notifications-container p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 10px;
}

.icon-img {
  width: 20px;
}
.navlink-images {
  width: 35px;
  height: 30px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%)
    hue-rotate(25deg) brightness(104%) contrast(101%);
}
.navlink-inactive-div span > img {
  vertical-align: middle;
}
.navlink-active-image {
  filter: brightness(0) saturate(100%) invert(29%) sepia(79%) saturate(3107%)
    hue-rotate(335deg) brightness(90%) contrast(112%);
}

.navlink-inactive-div {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  cursor: pointer;
}
.navlink-active-div {
  background-color: #ecf4ff;
  transition: 0.5s ease-in-out;
  position: relative;
}
.navlink-inactive-div:hover {
  background-color: #e7ebf036;
  transition: 0.3s ease-in-out;
}
.child {
  position: absolute;
  font-size: large;
  left: 65px;
  top: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  width: 270px;
  z-index: 9;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.04);
  z-index: 8;
  overflow: hidden;
}
.child h4 {
  font-size: 18px;
  font-weight: 600;
  color: #1b224c;
}
.child div {
  cursor: pointer;
}
.childg {
  display: none;
}

/****open*/
.nav-open {
  align-self: flex-start;
  padding-left: 20px;
  width: 100%;
}
.navlink-title {
  color: #fff;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 0;
  opacity: 0;
  height: 0;
  overflow: hidden;
  white-space: nowrap;
  transition: max-width 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.navlink-title-opened {
  max-width: 100%;
  height: 100%;
  opacity: 1;
}
.navlink-inactive-openedDiv {
  display: flex;
  align-items: center;
  gap: 18px;
  cursor: pointer;
  padding: 8px;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
}
.navlink-inactive-openedDiv:hover {
  background-color: #e7ebf036;
  transition: 0.3s ease-in-out;
}
.navlink-openedimages {
  width: 25px;
  height: 15.625px;
  flex-shrink: 0;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%)
    hue-rotate(25deg) brightness(104%) contrast(101%);
}
.navlink-active-openedDiv {
  background-color: #ecf4ff;
}
.navlink-active-openedDiv .navlink-title {
  color: #f3223c;
  font-weight: 700;
}
.navlink-active-openedDiv .navlink-activeOpened-image {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.navlink-nested {
  transition: 0.3s ease-in-out;
}
.navlink-nested-imaage {
  filter: brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(924%)
    hue-rotate(296deg) brightness(108%) contrast(106%);
}
.navlink-activeNested-image {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.navlink-nested {
  padding-left: 22px;
}
.sidebar_subcont {
  display: none;
}
nav.navOPen {
  overflow-y: scroll;
}

@media screen and (max-height: 780px) and (max-width: 1550px) {
  nav {
    gap: 20px;
    /* align-items: center; */
  }
  .nav-closed {
    align-items: center;
  }
  .nav-open {
    padding-left: 0px;
  }
  .navlink-title {
    font-size: 14px;
  }
  .navlink-inactive-openedDiv {
    gap: 5px;
  }
  .navlink-inactive-div {
    width: 40px;
    height: 40px;
  }
  .navlink-images {
    width: 30px;
    height: 25px;
  }

  .userProfile {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 1300px) {
  .child h4 {
    font-size: 14px;
  }
  .child img {
    width: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .sidebar_subcont {
    display: block;
  }
  /* .navhead-subcontainer img,
  .navhead-title-arrowContainer img {
    width: 25px !important;
    height: 20px !important;
    padding-right: 0px;
  } */
  .navhead-title-arrowContainer {
    gap: 0.4rem;
  }
  /* .navhead-container .userprofile {
    width: 55px !important;
    height: 55px !important;
  } */
  .navhead-container {
    height: 7vh;
    padding: 1.8rem 0.5rem;
  }

  nav {
    gap: 8px;
    /* overflow-y: scroll; */
  }
  .navlink-title {
    font-size: 14px;
  }
  .child h4 {
    font-size: 14px;
  }
}
@media screen and (max-width: 800px) {
  .userProfile {
    width: 25px;
    height: 25px;
  }
}
