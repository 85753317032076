.custom-progress-bar #progress-bar span {
  background-color: red !important; /* Change the color here */
}
.css-ddvotl-ProgressBar {
  background-color: red !important; /* Change the color here */
}

@media screen and (max-width: 800px) {
  .custom-progress-bar {
    display: none;
  }
}
