.sub-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.personal-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 26px;
  background-color: #fff;
  border-radius: 10px;
}
.personalsub-container {
  display: flex;
  flex-direction: row;
  gap: 4rem;
}
.personalsub-container div,
.personal-container2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.first-container {
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 36px;
  align-items: center;
}
.parent {
  position: relative;
}
.child {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.subcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}
.subcontainer h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.details-subcontainer {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.details-subcontainer h4 {
  color: #1b224c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.details-subcontainer h5 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.title-container {
  display: flex;
  justify-content: space-between;
}
.title-container div {
  color: #f3223c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.title-subcontainer {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.title-subcontainer div {
  display: flex;
  gap: 0.7rem;
}

.edit-title {
  cursor: pointer;
  font-size: 20px;
}

.edit-title img {
  width: 20px;
  height: 20px;
}
.edit-title:hover {
  filter: brightness(0) saturate(100%) invert(11%) sepia(51%) saturate(1627%)
    hue-rotate(207deg) brightness(92%) contrast(95%);
}

.desktop_details {
  width: 100%;
}
.emailMobile {
  display: none;
}
.mobile_role_container {
  display: none;
}
.user-image {
  border-radius: 50%;
  height: 125px;
  width: 125px;
}

@media screen and (max-width: 1100px) {
  .first-container {
    padding: 20px;
  }
}
@media screen and (max-width: 950px) {
  .first-container {
    padding: 20px;
  }
  .subcontainer {
    gap: 6px;
  }
  .sub-container {
    grid-template-columns: 1fr;
  }
  .subcontainer h3 {
    font-size: 20px;
  }
  .parent img {
    width: 65px;
    height: 65px;
  }
  .child {
    width: 20px !important;
    height: 20px !important;
    right: 5px;
  }
  .title-container div {
    font-size: 15px;
  }
  .title-container div img {
    width: 15px !important;
    height: 15px !important;
  }
  .edit-title img {
    width: 15px;
    height: 15px;
  }
  .edit-title {
    cursor: pointer;
    font-size: 14px;
  }
}

@media screen and (max-width: 880px) {
  .first-container {
    padding: 20px;
    gap: 0.5rem;
  }

  .sub-container {
    display: none;
  }
  .subcontainer {
    display: none;
  }
  .child {
    display: none;
  }

  .title-container {
    flex-direction: column;
  }

  .desktop_details {
    display: none;
  }

  .emailMobile {
    display: block;
  }
  .emailMobile p {
    color: #1b224c !important;
    font-weight: 400 !important;
    font-size: 12px !important;
  }
  .mobile_role_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .details-subcontainer {
    gap: 0.3rem;
    width: 100%;
  }
  .details-subcontainer h4 {
    color: #1b224c;
    font-size: 14px;
  }
  .details-subcontainer h5 {
    font-size: 15px;
  }
}
