.grid_three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
.grid_two {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 10px;
}
@media screen and (max-width: 1300px) {
  .grid_three {
    grid-template-columns: 1fr 1fr;
  }
  .grid_two {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1000px) {
  .grid_three,
  .grid_two {
    grid-template-columns: 1fr;
  }
}
