.container {
  background-color: white;
  padding: 20px;
}
.audience_list_container {
  max-height: 64vh;
  overflow-y: scroll;
}
.title {
  color: #1b224c;
  font-size: 16px;
  font-weight: 700;
  padding: 24px 60px;
  background-color: #ecf4ff;
  border-radius: 10px;
}

.leftBorder {
  border-left: 8px solid #f3223c;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: 0.1s ease-in-out;
}

.row_name {
  color: #1b224c;
  font-size: 18px;
  font-weight: 400;
  border-radius: 10px;
  padding: 26px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  justify-content: flex-start;
}
/* .row_name p {
 padding-top: 10px;
  } */
.row_name.blue {
  background-color: #fafafc;
}
.title_filter {
  color: #000;
  font-size: 21px;
  font-weight: 700;
}

@media screen and (max-width: 1200px) {
  .audience_list_container {
    max-height:35vh;
  }
}
@media screen and (max-width: 1550px) and (max-height: 750px) {
  .title {
    font-weight: 700;
    padding: 13px 60px;
  }
  .title_filter {
    font-size: 18px;
  }
  .row_name {
    padding: 20px;
  }
    .audience_list_container {
    max-height: 35vh;
  }
}
@media screen and (max-width: 800px) {
  .title {
    font-size: 14px;
    padding: 20px 20px;
  }
  .row_name {
    padding: 15px;
  }
  .title_filter {
    color: #000;
    font-size: 14px;
    font-weight: 700;
  }  .audience_list_container {
    max-height: 30vh;
  }
}
