.tablePagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.tablePaginationCont,
.tablePaginationiul {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
}

.tablePagination h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.tablePagination img {
  height: 18px;
}
/* .tablePagination p {
  padding: 9px 25px;
  border-radius: 10px;
  border: 1px solid #1b224c;
  font-weight: 600;
}
.test {
  padding: 9px 15px;
  border-radius: 10px;
  border: 1px solid #1b224c;
  font-weight: 600;
  width: 58px;
} */
.pageSizeContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #1b224c;
  padding: 5px;
  border-radius: 10px;
}
.pageSizeContainer input {
  width: 35px;
  /* height: 30px; */
  border: none;
  padding: 15px;
}
.pageSizeContainer input:focus {
  border-color: none !important;
  outline: none !important;
}
.pageSizeContainer input[type="number"] {
  border-color: none !important;
  padding-block: 0px !important;
  padding-inline: 0px !important;
  outline: none !important;
}
.pageSizeContainer input[type="number"]:focus-visible {
  border-color: none !important;
  outline: none !important;
}

/* .pageSizeContainer input::-webkit-outer-spin-button,
.pageSizeContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pageSizeContainer input[type="number"] {
  -moz-appearance: textfield;
} */
.test:focus {
  padding: 9px 15px;
  border-radius: 10px;
  border: 1px solid #1b224c;
  font-weight: 600;
  width: 58px;
}
.pageItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #536a8d;
  background-color: #a6b8d2;
  cursor: pointer;
}
.disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #536a8d47;
  background-color: #a6b8d279;
  width: 32px;
  height: 32px;
}

.tablePaginationiul {
  list-style: none;
  text-decoration: none;
  list-style-type: none;
}
/* .tablePaginationiul a{
  color:#FFF
} */
.active {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  border-radius: 6px;
  background: #f3223c;
  width: 32px;
  height: 32px;
  border: 1px solid #f3223c;
}
.inactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #a6b8d2 !important;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #a6b8d2;
}
@media print {
  .tablePagination{
    display: none;
  }

}
@media screen and (max-width: 1000px) {
  .tablePaginationCont {
    display: none;
  }
  .tablePagination {
    justify-content: center;
    padding: 10px 0px;
    margin-top: 0;
  }
  .tablePaginationiul {
    padding: 0px;
  }
  .disabled,
  .pageItem,
  .active,
  .inactive {
    width: 30px;
    height: 30px;
  }
}
