.pieChart-cont {
  background-color: #fff;
  padding: 1rem;
  width: 100%;
  border-radius: 10px;
}
.pieChart-cont h2 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.stacked_graph {
  overflow-x: auto;
  padding-bottom: 1rem;
}

.stacked_graph_scrollable {
  width: 20%;
  min-width: 1200px;
}
@media screen and (max-width: 750px) {
  .pieChart-cont h2 {
    font-size: 13px;
  }
  .pieChart-cont {
    padding: 0.5rem;
  }
}
