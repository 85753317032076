.generalBody {
  display: flex;
  background-color: #f3f8ff;
}

.generalBody-subcont {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f3f8ff;
  flex: 1;
  width: calc(100vw - 264px);
}
.sidebar-subcont {
  /* margin-top: 3vh; */
  background-color: white;
  height: 100vh;
  border-bottom-right-radius: 50%;
}
@media screen and (max-width: 1200px) {
  .sidebar-subcont {
    display: none;
  }
}
