.cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.cont h3 {
  color: #000;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.whiteCont {
  background-color: white;
  padding: 10px;
  margin-top: 5px;
  border-radius: 10px;
}
@media screen and (max-width: 800px) {
  .cont img {
    height: 70px;
  }
  .cont h3 {
    font-size: 15px;
  }
}
