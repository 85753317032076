.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  vertical-align: middle;
}
.search-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  /* flex: 1; */
}
.icon-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
}
.icon-container h1 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.icon-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.button {
  display: none;
}
.button2 {
  display: block;
}
.icon-subcontainer h4 {
  color: #000;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.settings-cont {
  width: 100%;
  background-color: #fff;
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.029);
  height: 80vh;
  gap: 1rem;
}
.settings-cont h2 {
  color: #1b224c;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.settings-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.select-container {
  width: 300px;
  padding-left: 15px;
}
@media screen and (max-width: 1550px) and (max-height: 750px) {
  .icon-container {
    padding: 3px 0px;
  }
  .icon-container h1 {
    font-size: 25px;
  }
}
@media screen and (max-width: 1000px) {
  .icon-container {
    flex-wrap: wrap;
    gap: 10px;
  }
  .icon-container h1 {
    font-size: 20px;
  }
  .icon-container img {
    width: 20px !important;
    height: 20px !important;
  }
  .button {
    display: block;
  }
  .button2 {
    display: none;
  }
  .select-container {
    width: 52vw;
    padding-left: 0px;
  }
  .icon-subcontainer h4 {
    font-size: 14px;
  }

  .search-container{
    flex-direction: column;
    align-items: flex-start;
  }
}
