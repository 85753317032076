.sidebar {
  height: 93vh;
  width: 0;
  position: fixed;
  top: 7vh;
  right: -70vh;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.4s;
  z-index: 1000;
  padding: 0px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.632);

}

.image {
  cursor: pointer;
}
.sidebar.open {
  width: 40%;
  right: 0;
}

.sidebar .closeBtn {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  top: 0px;
  background-color: #1b224c;
  right: 25px;
  font-size: 36px;
  color: #fff;
  padding-bottom: 8px;
  position: sticky;
  padding-top: 8px;
  z-index: 1;
  cursor: pointer;

}
.icon{
  max-width:20px;
  max-height: 30px;
  filter: brightness(0) saturate(100%) invert(16%) sepia(9%) saturate(3931%) hue-rotate(194deg) brightness(92%) contrast(97%);
}
.closeBtn img {
  cursor: pointer;
}

ul {
  list-style: none;
  text-decoration: none;
  list-style-type: none;
  margin: 0;
  padding: 0px;
  overflow-y: scroll;
  color: #1b224c;
  padding-bottom: 20px;
}

.listItem {
  padding: 10px;
  font-size: 14px;
  transition: 0.4s ease-in-out;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #fafafc;
}
.listItem2 {
  padding: 10px;
  font-size: 14px;
  transition: 0.4s ease-in-out;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #fff;
}
.attributes {
  padding: 8px 25px;
  background-color: #1b224c;
  font-size: 15px;
  font-weight: 500;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #fff;
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}
.ul-cont ul li {
  color: #1b224c;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
}
.ul-cont li.activeListItem {
  color: #f3223c;
  background-color: #e7ebf0;
}
.ul-cont li.activeListItem img {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}

.ul-cont li:hover img {
  filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(18%)
    hue-rotate(288deg) brightness(105%) contrast(104%);
}
.ul-cont li:hover img {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.ul-cont li:hover {
  color: #f3223c;
}
.closeIcon {
  background-color: #1b224c;
  padding: 5px;
  border-radius: 5px;
  width: 26px;
  height: 24px;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .sidebar.open {
    width: 100%;
  }
}
