.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 20px;
  column-gap: 1px;
  padding-top: 40px;
}
.text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  background-color: #fff;
  padding: 40px 5px 25px 5px;
  border-radius: 10px;
  width: 136px;
}
.text_container h3 {
  color: #21231e;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
}
.text_container h4 {
  color: #21231e;
  text-align: center;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.space {
  background-color: #f3f8ff;
  height: 40px;
}
.nationality_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flag {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  width: 82px;
  height: 82px;
  object-fit: cover;
}
@media screen and (max-height: 780px) and (max-width: 1550px) { 
  .flag {
    width: 70px;
    height: 70px;
  }
.text_container{
  width: 130px;
}
}
@media screen and (max-width: 1000px) {
  .flag {
    top: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .text_container {
    width: 120px;
    padding: 15px 2px 9px 2px;
  }
  .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 5px;
    column-gap: 1px;
    padding-top: 0px;
  }

  .text_container h3 {
    max-width: 80px;
    font-size: 13px;
  }
  .text_container h4 {
    font-size: 15px;
  }
}

@media screen and (max-width: 800px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
