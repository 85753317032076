.documents-container {
  background-color: #fff;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.documents-headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.documents-headerContainer div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.documents-headerContainer h5 {
  color: #1b224c;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.documents-headerContainer .circle-icon {
  padding-top: 2px;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  background-color: #a6b8d2;
}
.documents-subContainer {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex-wrap: wrap;
}
.document {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.5rem;
  width: 177px;
  height: 184px;
}
.document:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.421);
}
.documetn h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.document p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.document .document-img {
  align-self: center;
}

.document-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.document-cont h4 {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.more-container {
  display: flex;
  flex-direction: column;
  z-index: 3;
}
.more-container div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 10px 15px;
}
.more-container div:nth-child(2) {
  background-color: #fafafc;
}
.more-container div:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
  color: #f3223c;
  background-color: transparent;
}

.more-container div:nth-child(2):hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
  color: #f3223c;
  background-color: transparent;
}

@media screen and (max-width: 800px) {
  .documents-subContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  
  }
  .documents-headerContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .documents-container {
    padding: 10px;
  }
  .documents-headerContainer h5 {
    font-size: 13px;
  }
  .document {
    width: 145px;
  }
}
