.filterIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .filterIcon img {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    cursor: pointer;
  }
  .filterIcon.open {
    background-color: #f3223c;
  }
  .filterIcon.open img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%)
      hue-rotate(164deg) brightness(102%) contrast(101%);
  }