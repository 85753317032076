.modal-container {
  position: fixed;
  padding: 30px 30px;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 12;
}

.modal-backdrop {
  background-color: #0000003b;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 10;
}
.modal-innerContainer {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  z-index: 12;
  animation: slide-up-fade-in 0.3s ease-out forwards;
}

@keyframes slide-up-fade-in {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-innerContainer .close-icon {
  position: absolute;
  top: 25px;
  right: 16px;
  cursor: pointer;
  font-size: 20px;
  opacity: 1;
}

.close-icon:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.modal-innerContainer h2 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}
/**********Settings modal*******/
.status-container {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  padding: 15px 5px;
}
.button-input-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  justify-content: center;
  width: 100%;
}

/**********delete modal*******/
.delete-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.delete-container h3 {
  color: #1b224c;
  text-align: center;
  font-family: Raleway;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.delete-container p {
  color: #1b224c;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 1230px) {
  .modal-innerContainer {
    width: 60vw !important;
  }
}

@media screen and (max-width: 900px) {
  .modal-innerContainer {
    width: 85vw !important;
    padding: 20px 15px;
  }

  .delete-container h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 800px) {
  .modal-innerContainer {
    width: 100vw !important;
    padding: 20px 10px;
  }

  .delete-container h3 {
    font-size: 20px;
  }
  .modal-container {
    position: fixed;
    padding: 30px 5px;
  }
  .modal-innerContainer h2 {
    font-size: 15px;
  }
  .close-icon {
    width: 20px;
    height: 20px;
  }
}
