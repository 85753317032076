.table-container {
  width: 100%;
  border-collapse: collapse;
}
.table-container thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.table-container th,
.table-container td {
  padding: 18px 15px;
  border: none;
  text-align: start;
  white-space: nowrap;
}
.table-container.with-borders th,
.table-container.with-borders td {
  border-left:3px solid #ffffff;
  border-right: 3px solid #ffffff;
  text-align: center !important;
}

.container {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}
.green-separator {
  border-right: 3px solid #1b224c23 !important;
}
.table-container thead {
  border-radius: 0px;
  background: #ecf4ff;
}

.table-container thead th {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.table-container thead th > img {
  vertical-align: middle;
}

.table-container tr:nth-child(even) {
  border-radius: 10px;
  background-color: #fafafc;
}
.arrowIcon {
  padding-left: 2px;
  text-align: center;
  vertical-align: middle;
}
.table-container tbody td {
  max-width: 200px;
  text-align: left;
  color: #1b224c;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.icon-cont-table {
  display: flex;
  gap: 1rem;
}
.table-container tbody td img:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
  cursor: pointer;
}
.table-container tbody td a {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.table-container tbody td a:hover {
  color: #f3223c;
}
.icon-cont-table img {
  height: 20px;
}
.arrowIcon:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.rotate-up {
  transform: rotate(180deg);
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.rotate-down {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}

@media screen and (max-width: 1650px) {
  .table-container th,
  .table-container td {
    padding: 20px 10px;
  }
  .table-container thead th {
    font-size: 13px;
  }
  .table-container tbody td {
    font-size: 13px;
  }
  .table-container tbody td a {
    font-size: 13px;
  }
  .icon-cont-table img {
    height: 15px;
  }
}
@media screen and (max-width: 1300px) {
  .table-container th,
  .table-container td {
    padding: 20px 5px;
  }
  .arrowIcon {
    height: 15px;
  }
}
@media screen and (max-width: 800px) {
  .table-container th,
  .table-container td {
    padding: 12px 5px;
  }

}
