.diamond {
  width: 80px;
  height: 80px;
  margin: 20px;
  padding: 20px;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  /* background-color: red; */
  border: 1px solid #eaeaea;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #fff;
}

.diamond_inner_container {
  display: flex;
  flex-direction: column;
  transform: rotate(314deg);
  align-items: center;
  justify-content: center;
}
.bigger_div {
  position: relative;
}

.line {
  position: absolute;
  width: 240px;
  height: 1px;
  background-color: #a9a9a9;
  top: 39px;
  right: -60px;
}
.circle {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50% 50%;
  top: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 8px;
  font-weight: 700;
  z-index: 2;
}
.circle.left {
  color: #2ba62c;
  background-color: #cbf3cc;
  left: -60px;
  border: 1px solid #2ba62c;
}
.circle.right {
  background-color: #ffeff1;
  right: -60px;
  border: 1px solid #d90606;
  color: #d90606;
}
.line_circle {
  position: absolute;
  width: 32px;
  height: 1px;
  background-color: #a9a9a9;
  top: 39px;
}
.line_circle.left {
  left: -90px;
}
.line_circle.right {
  right: -90px;
}
.vertical_line {
  position: absolute;
  width: 1px;
  height: 15px;
  background-color: #a9a9a9;
  top: 39px;
}
.vertical_line.left {
  left: -90px;
}
.vertical_line.right {
  right: -90px;
}

.plusLeft {
  position: absolute;
  top: 46px;
  left: -96px;
}
.plusRight {
  position: absolute;
  top: 46px;
  right: -96px;
}
.edit_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  gap: 25px;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  background-color: #fff;
}
.edit_container img {
  width: 14.045px;
  height: 14px;
}

.edit_container h2 {
  color: #1b224c;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.emailContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  padding: 12px;
  background-color: #fff;
  /* position: relative; */
}
.emailCategory_label {
  max-width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.emailContainer.selected,
.diamond.selected,
.edit_container.selected {
  border: 1px solid #f3223c;
}
.emailContainer img {
  width: 28px;
  height: 28px;
}
.emailTitle {
  position: absolute;
  border-radius: 31px;
  background: #235594;
  display: flex;
  height: 12px;
  padding: 0px 6px;
  top: 10px;
  justify-content: center;
  align-items: center;
  right: 5px;
}
.emailTitle h4 {
  color: #fff !important;
}
.emailContainer div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.emailContainer div h2,
.diamond_inner_container h2 {
  color: #000;
  text-align: center;
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.emailContainer div h4,
.diamond_inner_container h4 {
  color: #6e6e6e;
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.emailContainer div h3 span {
  color: #f3223c;
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
