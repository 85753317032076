.title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.container:hover .title_container img {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}

.container:hover .title_container h2,
.container:hover .title_container p {
  color: #f3223c;
}

.title_openContainer img {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}

.title_openContainer h2,
.title_openContainer p {
  color: #f3223c !important;
}

.title_container div {
  display: flex;
  align-items: center;
  gap: 13px;
}

.title_container div h2 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.queryContainer {
  display: grid;
  grid-template-columns: 0.2fr 1fr 0.5fr 4fr 0.2fr;
  grid-column-gap: 10px;
  /* align-items: center; */
  transition: 0.5s all;
}

.queryContainerTwo {
  grid-template-columns: 4fr 1fr 4fr 0.2fr;
}

.queryContainer_icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-top: 25px;
}

.queryContainer_icons img {
  cursor: pointer;
}

.queryBar_container {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  /* transition: max-height 0.5s ease, opacity 0.5s ease; */
  transition: 0.5s all;
}

.queryBar_container.open {
  max-height: 370px;
  overflow-y: scroll;
  opacity: 1;
  padding: 20px 5px 5px 5px;
  transition: 0.5s all;
  margin: 5px;
}

.img {
  /* transition: opacity 0.5s ease; */

  transition: transform 0.5s ease;
}

.rotate_img {
  /* transition: opacity 0.5s ease; */
  transform: rotate(180deg);
  transition: transform 0.8s ease;
}

@media screen and (max-width: 1550px) and (max-height: 750px) {
  .title_container div h2 {
    font-size: 18px;
  }
  .img {
 height: 20px;
  }
}
@media screen and (max-width: 1100px) {
  .queryContainer {
    grid-template-columns: 1fr 1fr;
    row-gap: 5px;
  }
}

@media screen and (max-width: 1000px) {
  .container img {
    width: 16px;
  }

  .queryContainer_icons {
    padding-top: 5px;
  }

  .queryContainer_icons img {
    width: 15px;
  }
}
