.export_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.export_container h1 {
  font-weight: 600;
  font-size: 20px;
  color: #1b224c;
}

.export_container p {
  font-size: 14px;
  font-weight: 600;
  color: #1b224c;
}

.export_container p span {
  font-size: 13px;
}


.print_only {
  display: none;
  padding-top: 20px;
}

@media print {
  .print_only {
    display: block;
  }
  .printOnlyCont {
    overflow: scroll;
    padding: 20px;
  }
  .export_container {
    overflow: visible !important;
    height: auto;
    width: auto;
  }

  .export_container h1 {
    font-size: 24px;
  }

  .export_container p {
    font-size: 16px;
  }
}
/* @media screen and (max-width: 800px) {
  .export_container {
    overflow: scroll;
  }
} */
