.campaignDetailsBox_cont {
  border-radius: 10px;
  border: 1px solid #bbbdc9;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.campaignDetailsBox {
  /* height: 136px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.campaignDetailsBox_cont:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.421);
}
.response_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding: 0 20px;
}
.responseBorder_container {
  border-right: 0.5px solid #bbbdc9;
}
.campaignDetailsBox_titleContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 30vw;
}
.dateStatus_container {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  gap: 10px;
  max-width: 70vw;
  /* align-items: center; */
}
.dateStatus_container img {
  vertical-align: middle;
}
.datecontainer {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.number {
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.stat_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}
.response_container h1 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.response_container div {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2px;
}
.response_container div h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.response_container div h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.datecontainer_mobile {
  display: none;
}
.mobile {
  display: none;
}

.isDeleted_type {
  border-radius: 4px;
  background-color: #f00;
  /* width: 94px; */
  height: 20px;
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  padding: 5px;
}
.isDeleted_type h5 {
  color: #fff;
  font-size: 10px;
  font-weight: 700;
}
@media screen and (max-height: 700px) and (max-width: 1300px) {
  .notes h2 {
    font-size: 13px;
  }
  .notes h3 {
    font-size: 12px;
  }
}
@media screen and (max-width: 1200px) {
  .datecontainer_mobile {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    gap: 10px;
  }
  .datecontainer {
    display: none;
  }
  .number {
    display: none;
  }
  .term {
    display: none;
  }
  .campaignDetailsBox_cont {
    padding: 10px;
  }
  .mobile {
    display: flex;
    gap: 2px;
  }
}

@media screen and (max-width: 850px) {
  .datecontainer_mobile {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    gap: 10px;
  }
  .datecontainer {
    display: none;
  }
  .number {
    display: none;
  }
}
