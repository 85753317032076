.steps-bar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 20px !important;
  overflow: hidden;
}
.steps-bar p {
  font-size: 18px;
}
.bar {
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  position: relative;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  transition: 0.2s all ease-in-out;
}

.bar:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -40px;
  width: 40px;
  height: 60px;
  background-color: currentColor;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  transform: translateY(-50%);
  color: currentColor;
  z-index: 6;
}

.bar.active {
  color: #f3223c;
  background-color: #f3223c;
  transition: 0.2s all ease-in-out;
}
.bar.secondInactive {
  color: #f87a8a;
  background-color: #f87a8a;
  transition: 0.2s all ease-in-out;
}
.bar.active p,
.bar.inactive p,
.bar.done p,
.bar.secondInactive p {
  color: #fff;
}
.bar.inactive {
  color: #f76477;
  background-color: #f76477;
  transition: 0.2s all ease-in-out;
}
.bar.inactive p {
  opacity: 0.9;
}
.bar.done {
  background-color: #1b224c;
  color: #1b224c;
  transition: 0.2s all ease-in-out;
}
.bar.done img {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 1000px) {
  .bar {
    padding: 12px;
    gap: 5px;
    font-size: 12px;
    font-weight: 700;
  }

  .bar:not(:last-child)::after {
    top: 50%;
    right: -8px;
    width: 34px;
    height: 58px;
  }
  .bar.done img {
    display: none;
  }
  .steps-bar {
    border-radius: 5px !important;
  }
}
@media screen and (max-width: 880px) {
  .steps-bar p {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .bar {
    padding: 8px 5px;
  }

  .bar:not(:last-child)::after {
    top: 50%;
    right: -8px;
    width: 19px;
    height: 39px;
  }
}
