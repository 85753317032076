.email_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.email_container h3 {
  color: #1b224c;
  font-size: 16px;
  font-weight: 600;
}
.email_container img {
  max-width: 200px;
  max-height: 200px;
}
.email_subContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
}
.conditionsCont {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
