.receivedEmail-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  min-height: 40vh;
}
.receivedEmail_mobilecontainer {
  display: none;
}
.title_cont {
  display: flex;
  gap: 10px;
}
.image {
  width: 35px;
  height: 35px;
}
.title_subCont {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}
.title_cont p {
  font-size: 14px;
  font-weight: 700;
}
.title_cont h3 {
  font-size: 10px;
}
@media screen and (max-width: 800px) {
  .receivedEmail-container {
    display: none;
  }
  .receivedEmail_mobilecontainer {
    display: grid;
    grid-template-columns: 1fr;
  }
}
