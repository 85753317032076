.settings-container {
  display: flex;
  gap: 2rem;
}
.settings-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.burgerIcon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .burgerIcon {
    display: block;
  }
  .settings-bulk {
    display: none;
  }
}
