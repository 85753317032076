.user-modal-container {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
}
.user-modal-subcontainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 30vw;
  padding-bottom: 1rem;
}

.useModal_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
@media screen and (max-width: 1230px) {
  .user-modal-subcontainer {
    width: 30vw !important;
  }
  .useModal_container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 900px) {
  .user-modal-subcontainer {
    width: 45vw !important;
  }
}
