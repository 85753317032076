.notes-leadProspect-cont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.Notes-container {
  padding: 26px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  background-color: #fff;
  max-height: 500px;
  overflow-y: scroll;
}
.Notes-subcontainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Notes-container h2 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subcontainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.notes-title-cont {
  display: flex;
  flex-direction: column;
}
.notes-title-cont h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.notes-title-cont h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Notes-subcontainer p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  resize: none;
  word-wrap: break-word;
}
.notes-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.more-container-icons {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  gap: 10px;
}
.more-container-icons:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
  color: #f3223c;
}

@media screen and (max-width: 800px) {
  .notes-details {
    flex-wrap: wrap;
  }
  .Notes-container {
    padding: 10px;
  }
  .notes-details h2 {
    font-size: 14px;
  }

  .subcontainer img {
    width: 40px;
  }
  .notes-title-cont h3 {
    font-size: 13px;
  }
  .notes-title-cont h4 {
    font-size: 12px;
  }
  .Notes-subcontainer p{
    font-size: 14px;
  }
  .notes-leadProspect-cont {
    display: grid;
    grid-template-columns: 1fr ;
    gap: 1rem;
  }
}
