.tab-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.onlinform-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.applicationTab-subcontainer {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  flex-wrap: wrap;
  gap: 10px;
}
.status-icon {
  color: #f3223c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  display: flex;
  gap: 5px;
  white-space: nowrap;
}
.status-icon img {
  width: 25px;
}
.status-icon:hover {
  filter: brightness(0) saturate(100%) invert(15%) sepia(10%) saturate(3874%)
    hue-rotate(194deg) brightness(89%) contrast(100%);
  color: #1b224c;
}
.tab-subcontainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.tab-subcontainer h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.tab-subcontainer h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.parent-sub {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.parent-sub:hover {
  color: #f3223c;
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
  position: relative;
}
.dropDown-container {
  position: absolute;
  z-index: 5;
  width: 200px;
  top: 1rem;
  left: 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}
.details-subcontainer {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 2.5rem;
  flex-wrap: wrap;
}
.details-subcontainer div {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  /* max-width: 200px; */
  align-items: center;
}
.details-subcontainer h4 {
  color: #1b224c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.details-subcontainer h5 {
  color: #1b224c;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.select_subcontainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

@media screen and (max-height: 700px) and (max-width: 1300px) {
  .select_subcontainer h3 {
    font-size: 15px;
  }
}
@media screen and (max-width: 1200px) {
 
  .applicationTab-subcontainer {
    flex-wrap: wrap;
    gap: 1rem;
  }
  .details-subcontainer {
    gap: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .tab-subcontainer,
  .details-subcontainer {
    gap: 0.5rem;
  }
  .tab-subcontainer h3 {
    font-size: 14px;
  }
  .details-subcontainer h4 {
    font-size: 14px;
  }
  .details-subcontainer h3 {
    font-size: 13px;
  }
  .onlinform-container {
    gap: 0.5rem;
  }
 .status-icon {
    font-size: 14px;
  }
  .status-icon img {
    width: 15px;
  }
  .select_subcontainer {
    display: flex;
    flex-direction: row;
    gap: 1px;
    align-items: center;
  }
  .select_subcontainer h3 {
    font-size: 13px;
    white-space: nowrap;
  }
  .applicationTab-subcontainer {
    padding: 10px;
  }
}
