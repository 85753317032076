.stacked_graph {
  overflow-x: auto;
  padding-bottom: 1rem;
}

.stacked_graph_scrollable {
  width: 20%;
  min-width: 1000px;
}

@media screen and (max-width: 1000px) {
  .stacked_graph_scrollable {
    width: 3000px;
    min-width: 1000px;
  }
}
