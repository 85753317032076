* {
  margin: 0;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0px;
  box-sizing: border-box;
}
body {
  max-width: 2600px;
  margin: auto;
}
.timeline {
  overflow: scroll;
  /* max-height: 400px; */
  max-height: calc(100vh - 500px);
  display: flex;
  padding-left: 48px;
  flex-direction: column;
}
.timeline__event {
  position: relative;
  display: flex;
  margin: 20px 0;
  /* cursor: pointer; */
}
/* .timeline__event__content:hover p,
.timeline__event__content:hover h3 {
  color: #1b224cbf !important;
} */
.timeline__event__title {
  font-size: 1.2rem;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #9251ac;
  letter-spacing: 1.5px;
}
.timeline__event__content h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.timeline__event__content p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.timeline__event__date {
  color: #f6a4ec;
  font-size: 1.5rem;
  font-weight: 600;
  white-space: nowrap;
}

.timeline__event__icon img {
  position: absolute;
  top: 50%;
  left: -40px;
  font-size: 2.5rem;
  transform: translateY(-50%);
}
.timeline__event__description {
  flex-basis: 60%;
}
.timeline__event:after {
  content: "";
  width: 1px;
  height: 42.1px;
  background: #a6b8d2;
  position: absolute;
  top: 36px;
  left: -1.4rem;
  z-index: 1;
}

.timeline__event:last-child:after {
  content: none;
}
@media screen and (max-width: 800px) {
  .timeline {
    max-height: 100vh;
  }
}
/********text editor **/
.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  min-height: 200px;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.ql-toolbar.ql-snow {
  border: none !important;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
}
.ql-container.ql-snow {
  border-left: 1px solid #1b224c !important;
  border-bottom: 1px solid #1b224c !important;
  border-right: 1px solid #1b224c !important;
}
.ql-editor img {
  max-width: 100%;
  height: auto;
}

.ql-picker.ql-insertCustomTags {
  width: 180px !important;
}
.ql-insertCustomTags .ql-picker-item:before {
  content: attr(data-label);
}
.ql-snow
  .ql-picker.ql-insertCustomTags
  .ql-picker-label[data-label]:not([data-label=""])::before {
  content: attr(data-label);
  font-weight: 600;
}

/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}
/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
  font-size: 12px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
  font-size: 20px !important;
}
.ql-size-huge,
.ql-size span[data-value="huge"]::before {
  font-size: 24px !important;
}
.ql-editor p img {
  width: 10vw !important;
  height: auto !important;
}

/***phone input**/
.react-tel-input .form-control {
  border: none !important;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: white !important;
  border: none !important;
  /* border-right: 1px solid #1b224cb9 !important; */
}
.react-datepicker-popper {
  z-index: 999 !important;
}
.quill-container {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
}
#SvgjsCircle3506 {
  display: none;
}
#SvgjsSvg8403,
#apexchartsvoyd6bv4 {
  height: 230px;
}
@media screen and (max-height: 700px) and (max-width: 1300px) {
  .timeline__event__content h3 {
    font-size: 13px;
  }
  .timeline__event__content p {
    font-size: 13px;
  }
}
@media screen and (max-width: 1000px) {
  .quill-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .quill-container select {
    padding-left: 10px;
  }
}
@media screen and (max-width: 800px) {
  .timeline__event__content h3 {
    font-size: 13px;
  }
  .timeline__event__content p {
    font-size: 13px;
  }
  .quill-container {
    flex-direction: column;
  }
}

/* html {
  font-size: 62.5%;
}
@media screen and (max-width: 950px) {
  html {
    font-size: 12.5%;
  }
} */

.apexcharts-toolbar {
  background-color: white;
  color: white;
  border-radius: 4px;
  z-index: -1;
}

.apexcharts-toolbar button {
  color: white;
}

.apexcharts-toolbar svg {
  fill: white !important;
}
.apexcharts-toolbar .apexcharts-menu-icon {
  cursor: none;
}
.tox.tox-tinymce {
  height: 350px !important;
}
.tox-tinymce{
  border: 1px solid #1b224c !important;
}

.tox-tinymce.error{
  border: 1px solid red !important;
}

.tox .tox-edit-area::before{
  border: none !important;
}