.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.loader {
  border: 4px solid #1b224c;
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
  border-radius: 50%;
}
@media screen and (max-width: 1000px) {
  .loader {
    border: 4px solid #1b224c;
    border-left-color: transparent;
    width: 18px;
    height: 18px;
    animation: spin89345 1s linear infinite;
    border-radius: 50%;
  }
}
@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
