.dashboard-container {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.dashboard-container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e7ebf0;
  padding: 18px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.349);
}

.dashboard-container h1 {
  color: #f3223c;
}
.dashboard-container h4 {
  color: #f3223c;
}
.chart-div {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.statistical-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}
.statistical-sub-cont {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
}
.statistical-sub-cont h4 {
  font-size: 17px;
  color: #1b224c;
  font-weight: 700;
}
.stat-img-sub-cont {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-end;
}

.stat-img-sub-cont h3 {
  font-size: 21px;
  color: #1b224c;
  font-weight: 700;
}

.welcome-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}
.welcome-div h2 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.stat-img {
  height: 20px;
}
.tab_more_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffff;
  padding: 10px;
  border-radius: 10px;
  gap: 5px;
}

@media print {
  .noPrint{
    display: none;
  }

}
@media screen and (max-width: 1200px) {
  .item1 {
    grid-column: 1 / -1;
  }
  .chart-div {
    flex-direction: column;
  }
  .statistical-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
  .welcome-div h2 {
    font-size: 18px;
  }
  .statistical-sub-cont h4 {
    font-size: 14px;
  }
  .stat-img-sub-cont h3 {
    font-size: 14px;
  }
  .stat-img {
    height: 18px;
  }
  .statistical-sub-cont {
    padding: 14px;
  }
}
@media screen and (max-height: 900px) and (max-width: 1400px) {
  .welcome-div {
    padding: 10px;
  }
  .welcome-div h2 {
    font-size: 17px;
  }
}
@media screen and (max-width: 800px) {
  .welcome-div img {
    height: 18px;
    width: 18px;
  }
  .welcome-div {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .statistical-div {
    gap: 0.4rem;
  }
  .welcome-div h2 {
    font-size: 18px;
  }
  .statistical-sub-cont h4 {
    font-size: 14px;
  }
  .stat-img-sub-cont h3 {
    font-size: 13px;
  }
  .stat-img {
    height: 14px;
  }
  .statistical-sub-cont {
    padding: 10px;
  }
}
