.cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}
.cont img {
  filter: none !important;
  height: 270px;
  cursor: auto;
}
.cont h3 {
  color: #000;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 800px) {
  .cont img {
    height: 70px;
  }
  .cont h3 {
    font-size: 13px;
    font-weight: 500;
  }
  .table {
    align-items: flex-start;
    padding-left: 2rem;
  }
}
