.statistical-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}
.statistical-sub-cont {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
  padding: 12px 20px;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}
.statistical-sub-cont h4 {
  font-size: 17px;
  color: #1b224c;
  font-weight: 700;
}
.statistical-sub-cont img {
  width: 45px;
  height: 45px;
}

.statistical-sub-cont h3 {
  font-size: 21px;
  color: #1b224c;
  font-weight: 700;
}


@media screen and (max-height: 900px) and (max-width: 1550px) {
  .statistical-sub-cont {
    padding: 14px;
    gap: 0.2rem;
  }
  .statistical-sub-cont img {
    height: 30px;
    width: 30px;
  }
  .statistical-sub-cont h3 {
    font-size: 18px;
  }
  .statistical-sub-cont h4 {
    font-size: 15px;
    color: #1b224c;
    font-weight: 700;
  }
}
@media screen and (max-width: 1200px) {
  .statistical-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.5rem;
  }
  .welcome-div h2 {
    font-size: 18px;
  }
  .statistical-sub-cont h4 {
    font-size: 14px;
  }
  .statistical-sub-cont h3 {
    font-size: 14px;
  }
  .stat-img {
    height: 18px;
  }
  .statistical-sub-cont {
    padding: 14px;
  }
  .statistical-sub-cont img {
    width: 18px;
    height: 18px;
  }
}
@media screen and (max-width: 800px) {
  .statistical-div {
    grid-template-columns: 1fr 1fr;
    gap: 0.4rem;
  }

  .statistical-sub-cont h4 {
    font-size: 14px;
  }
  .statistical-sub-cont h3 {
    font-size: 13px;
  }
  .stat-img {
    height: 14px;
  }
  .statistical-sub-cont {
    padding: 10px;
  }
}
