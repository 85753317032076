.singleApplicantTopHeader_container {
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 36px;
}
.first-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}
.subcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.emailContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
}
.subcontainer h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.details-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.details-subcontainer h4 {
  color: #1b224c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.details-subcontainer h5 {
  color: #1b224c;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.title-subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #f3223c;
  flex-wrap: wrap;
  align-items: center;
}
.title-subcontainer-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.caution-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.title-subcontainer a {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.title-subcontainer a:hover {
  color: #f3223c !important;
}
.title-pop-subcontainer div {
  color: #f3223c;
  font-family: Raleway;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
}
.title-pop-subcontainer div img {
  width: 25px;
}
.details-subcontainer {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 2.5rem;
}
.details-subcontainer div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 200px;
}

.tab-singleapp-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  border-radius: 10px;
  background-color: #fff;
  gap: 2rem;
}
.tab-singleapp-subcontainer {
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  gap: 2rem;
}

.title-pop-subcontainer div:hover {
  filter: brightness(0) saturate(100%) invert(15%) sepia(10%) saturate(3874%)
    hue-rotate(194deg) brightness(89%) contrast(100%);
  color: #1b224c;
}
.subTitle-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}
.subTitle-img-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.subTitle-img-cont p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.campaign_header_date {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.campaign_header_date h3 {
  color: #000;
  font-size: 15px;
  font-weight: 600;
}

.campaign_header_date h4 {
  color: #1b224c;
  font-size: 17px;
  font-weight: 500;
}
.mobile {
  display: none;
}
.desktop {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.recruitment_type_span {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.recruitment_type {
  border-radius: 4px;
  background-color: #f00;
  /* width: 94px; */
  height: 20px;
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  padding: 5px 8px;
}
.recruitment_type h5 {
  color: #fff;
  font-size: 10px;
  font-weight: 700;
}
@media screen and (max-height: 780px) and (max-width: 1550px){
  .singleApplicantTopHeader_container {
    padding: 13px;
  }
  .userProfile {
  height: 120px;
  }
  .subcontainer h3 {
    font-size: 18px;
  }
  .title-subcontainer a {
    font-size: 14px;
  }
  .title-subcontainer img {
    height: 12px;
  }

  .tab-singleapp-subcontainer {
    gap: 1rem;
  }
  .campaign_header_date h3 {
    color: #000;
    font-size: 14px;
    font-weight: 600;
  }

  .campaign_header_date h4 {
    font-size: 13px;
  }

}
@media screen and (max-height: 700px) and (max-width: 1300px) {
 
  .userProfile {
    width: 70px;
  }

}
@media screen and (max-width: 800px) {
  .singleApplicantTopHeader_container {
    padding: 10px;
    gap: 0.5rem;
  } .userProfile {
  height:80px;
  }
  .first-container {
    gap: 0.5rem;
    align-items: flex-start;
  }
  .userProfile {
    width: 60px;
    padding-top: 13px;
  }
  .subcontainer {
    gap: 5px;
  }
  .subcontainer h3 {
    font-size: 20px;
  }
  .title-subcontainer {
    flex-direction: row;
    gap: 5px;
  }
  .title-pop-subcontainer div {
    align-items: center;
    justify-content: flex-end;
    font-size: 13px;
  }
  .title-pop-subcontainer div img,
  .subcontainer img {
    width: 15px;
  }
  .subTitle-cont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px;
  }
  .title-subcontainer-div {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
  .mobile {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .desktop {
    display: none;
  }

  .caution-container {
    font-size: 13px;
  }
  .title-pop-subcontainer div {
    justify-content: flex-start;
  }
  .details-container {
    justify-content: flex-start;
  }
  .campaign_header_date h3 {
    font-size: 14px;
  }

  .campaign_header_date h4 {
    font-size: 13px;
  }
}
