.progressBox_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 5vw;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
}
.details {
  display: flex;
  flex-direction: column;
  gap: 23px;
}
.subDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subDetails > div {
  color: #f3223c;
  font-size: 28px;
  font-weight: 700;
}
.details_total_number {
  color: #f3223c;
  font-size: 28px;
  font-weight: 700;
}
@media screen and (max-width: 1450px) {
  .titleContainer h3 {
    font-size: 18px;
  }
  k .details_total_number {
    font-size: 20px;
  }
}
@media screen and (max-width: 1300px) {
  .progressBox_container {
    gap: 2vw;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .subDetails {
    gap: 5px;
  }

  .CampaignProgressBarBox_details {
    gap: 18px;
  }
}

@media screen and (max-width: 800px) {
  .progressBox_container {
    padding: 10px;
  }
  .progressBox_container {
    gap: 2vw;
    grid-template-columns: 1fr 1fr;
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .details_total_number {
    font-size: 17px;
  }
}
