/*unfilled component*/
.filled-text,
.unfilled-text {
  font-family: Raleway;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}
.filled-text a {
  color: #1b224c !important;
  font-family: Raleway;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}
.filled-text a:hover {
  color: #f3223c !important;
}
p > img,
.unfilled-text > img {
  vertical-align: middle;
  width: 14px;
  height: 14px;
}

/*Text Container*/
.text-container {
  display: flex;
  flex-direction: row !important;
}
.text-container.column {
  flex-direction: column !important;
}
.text-container p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.text-container h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.text-container span {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: wrap;
}
/* .flag {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
} */
.flag {
  border-radius: 4px;
  background-color: #f00;
  /* width: 94px; */
  height: 20px;
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  padding: 5px;
}
.flag h5 {
  color: #fff;
  font-size: 10px;
  font-weight: 700;
}
@media screen and (max-width: 800px) {
  .text-container p {
    font-size: 14px;
  }
  .text-container h3 {
    font-size: 14px;
  }
  .text-container span {
    font-size: 14px;
  }
  .filled-text,
  .unfilled-text {
    font-size: 12px !important;
  }
  .filled-text a {
    font-size: 14px !important;
  }
  p > img,
  .unfilled-text > img {
    /* vertical-align:bottom; */
    width: 13px !important;
    height: 13px !important;
  }
}
