.sidebar-container {
  position: relative;
  background-color: #1b224c;
  width: 113px;
  border-radius: 0px 40px 40px 0px;
  /* height: calc(100% - 43px); */
  height: 100vh;
  padding: 30px 15px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 8;
  transition: 0.4s all;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 59%);
}

.sidebar-container.open {
  width: 264px;
  transition: 0.4s all;
}
.closeIcon {
  transition: transform 0.8s ease;
}
.openedIcon {
  transform: rotate(180deg);
  transition: transform 0.8s ease;
}
.logo {
  transition: 0.4s all;
}
.coll-exp-container {
  position: absolute;
  right: -20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

@media screen and (max-height: 780px) and (max-width: 1550px) {
  .logoImage {
    height:20px !important
  }

  .closeIcon{
    height:20px
  }
  .coll-exp-container {
    position: absolute;
    right: -10px;
  }

  .sidebar-container.open {
    width: 200px;
  }
  .sidebar-container {
    padding: 30px 10px;
    width:100px
  }
}