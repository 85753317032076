.colored_select_container {
  position: relative;
  display: inline-block;
  width: 180px;
}
.colored_select_subcontainer {
  display: flex;
  flex-direction: row;
  width: 180px;
  align-items: center;
  justify-content: space-between;
  background-color: #ffeff1;
  padding: 10px 12px;
  cursor: pointer;
  border-radius: 5px;
}
.colored_select_subcontainer h2 {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #f3223c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 12;
  width: 100%;
}

.dropdown_menu div {
  cursor: pointer;
  padding: 18px;
  border-radius: 10px;
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dropdown_menu div:hover {
  background-color: #f1f1f1;
}

.even_option {
  background-color: #fafafc;
}

.odd_option {
  background-color: #ffff;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.04);
  z-index: 11;
}
.arrow_icon {
  transition: 0.4s ease-in-out;
}
.openedArrow {
  transform: rotate(180deg);
  transition: 0.1s ease-in-out;

}
@media screen and (max-height: 900px) and (max-width: 1400px) {
  .colored_select_subcontainer {
    padding: 5px;
  }
}

@media screen and (max-width: 800px) {
  .dropdown_menu div {
    font-size: 14px;
  }
  .colored_select_subcontainer h2 {
    font-size: 14px;
  }

  .colored_select_subcontainer img {
    height: 14px;
    width: 14px;
  }
  .dropdown_menu div {
    padding: 10px;
  }
}
