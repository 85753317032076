.parent {
  cursor: pointer;
}
.parent-sub {
  position: relative;
}

.menu-container {
  z-index: 5;
  position: absolute;
  background-color: white;
  width: 200px;
  top: 2rem;
  right: 0rem;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 64%);
}
.profile-container {
  position: absolute;
  background-color: white;
  z-index: 2;
  top: 3rem;
  right: 0rem;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

.more-img {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
.profile-img {
  width: 50px;
  height: 50px;
  vertical-align: middle;
}

.more-img:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}

.profile-img:hover {
  filter: none !important;
}
.more-img.off:hover,
.profile-img.off:hover {
  filter: none !important;
}
.title-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.title-container h3 {
  font-size: 18px;
  font-weight: 600;
  color: #1b224c;
}
/* .title-container:hover img {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.title-container.off:hover img {
  filter: none;
} */
.title-container:hover h3 {
  color: #f3223c;
}

/* .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.04);
  z-index: 11;
  overflow: hidden;
} */


@media screen and (max-width: 800px) {
  .more-img {
    width: 16px;
    height: 16px;
  }
  .profile-img {
    width: 25px;
  }
  .max_inner_width {
    max-width: 70vw !important;
  }
  .profile-container {
    width: 30vw !important;
  }

}
