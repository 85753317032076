.grid_container {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 10px;
}
.chartCont {
  background-color: white;
  border-radius: 10px;
}
.title_container {
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fafafc;
  border-radius: 10px;
  cursor: pointer;
}
.title_container.active h2 {
  color: #f3223c !important;
  font-weight: 500;
}
.title_container.active {
  background: #dadada3d;
}
.title_container h2 {
  color: #1b224c82 !important;
  font-size: 16px;
  font-weight: 500;
}
.title_container h4 {
  color: #676e99;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
}
.even {
  background: #fafafc00;
}
.receiver_cont ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.receiver_cont ul li {
  padding: 15px;
  border-radius: 15px;
}
.receiver_even {
  background-color: #fafafc;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.2rem;
  height: 80vh;
  overflow: scroll;
  border-radius: 10px;
}
.container h1 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subContainer {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}
@media screen and (max-width: 1000px) {
  .mobile_responsive {
    display: none;
  }
  .grid_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
