.single-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.single-tab img {
  width: 25px;
  height: 25px;
}
.active-tab {
  padding-bottom: 2px;
  border-bottom: 2px solid #f3223c;
  cursor: pointer;
}
.single-tab:hover {
  color: #f3223c;
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}

.inactive-tab {
  padding-bottom: 2px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.active-tab h3 {
  color: #f3223c;
  font-family: Raleway;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.inactive-tab h3 {
  color: #8d90a5;
  font-family: Raleway;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.activeTabImage {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.text-style {
  font-size: 17px;
  font-weight: 600;
}
.tab-singleapp-subcontainer {
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  gap: 2rem;
  padding: 23px;
}

.blueImage {
  filter: brightness(0) saturate(100%) invert(34%) sepia(82%) saturate(4884%)
    hue-rotate(189deg) brightness(100%) contrast(100%);
}
@media screen and (max-height: 780px) and (max-width: 1550px) {
  .tab-singleapp-subcontainer {
    padding: 10px;
  }
  .active-tab h3 {
    font-size: 16px;
  }
  
  .inactive-tab h3 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1390px) {
  .active-tab h3 {
    font-size: 15px;
  }

  .inactive-tab h3 {
    font-size: 15px;
  }
  .tab-singleapp-subcontainer {
    gap: 1rem;
    justify-content: none;
  }
  .single-tab img {
    width: 15px;
    height: 15px;
  }
}



@media screen and (max-height: 700px) and (max-width: 1300px) {
  .tab-singleapp-subcontainer {
    gap: 1rem;
    padding: 15px;
  }
  .inactive-tab h3,
  .active-tab h3 {
    font-size: 12px;
  }
}
@media screen and (max-width: 800px) {
  .tab-singleapp-subcontainer {
    gap: 0.8rem;
    padding: 15px 5px;
  }
  .active-tab h3 {
    font-size: 13px;
  }

  .inactive-tab h3 {
    font-size: 13px;
  }
  .single-tab img {
    display: none;
  }
  .active-tab {
    border-bottom: none;
  }
  .inactive-tab {
    border-bottom: none;
  }
  .active-tab h3 {
    border-bottom: none;
    color: #fff;
    background-color: #f3223c;
    padding: 6px;
    border-radius: 20px;
  }
  .inactive-tab h3 {
    border-bottom: none;
    padding: 6px;
    border-radius: 20px;
  }
  .single-tab:hover {
    color: #fff;
    filter: none;
  }

  .tab-singleapp-subcontainer {
    white-space: nowrap;
    overflow-x: auto;
  }
  .rounded-single-tab img {
  display: none;
}
.rounded-active-tab {
  border-bottom: none;
}
.rounded-inactive-tab {
  border-bottom: none;
}
.rounded-active-tab h3 {
  border-bottom: none;
  color: #fff;
  background-color: #f3223c;
  font-weight: 600;
  padding: 6px;
  font-size: 15px;
  border-radius: 20px;
}
.rounded-inactive-tab h3 {
  border-bottom: none;
  padding: 6px;
  background-color: transparent;
  color: #8d90a5;
  border-radius: 20px;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.rounded-active-tab:hover {
  color: #fff;
  filter: none;
}
}
