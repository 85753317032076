.chart_text_container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.text_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.chart_container {
  /* width: 74.967px;
  height: 75.254px; */
  width: 20%;
  min-width: 80px;
}
.text_container h1 {
  color: #21231e;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text_container h2 {
  color: #6a6565;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width:800px){
  .text_container h1 {
    font-size: 15px;
  }
  .text_container h2 {
    font-size: 12px;
  }
}