.tippy-box {
  border-radius: 40px !important;
  background-color: #1b224c !important;
  font-weight: 600;
  font-size: 13px;
}

.tippy-box.red-tooltip {
  background-color: #d90606 !important;
}
.tippy-box.padding-tooltip {
  padding: 5px;
  border-radius: 10px !important;
}
