.mobile_buttons {
  display: none;
}
.cont{
  display: flex;
  flex-direction: column;
  gap:10px
}
@media screen and (max-width: 800px) {
  .mobile_buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-end;
  }
  .mobile_header {
    display: none;
  }
}
