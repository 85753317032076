.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  height: 67vh;
  overflow-y: scroll;
}

@media screen and (max-width: 1200px) {
  .form {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
  }
}
