.chart_container {
  /* width: 50vw; */
  height: 50vh;
}

@media screen and (max-width: 800px) {
  .chart_container {
    width: 95vw;
    height: 100vh;
  }
}
