/* RolesDropDown.module.css */
.Rolescontainer {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  max-height: 400px;
  overflow-y: scroll;
}
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: #fafafc;
  padding: 18px;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.container div img {
  width: 20.8px;
  height: 24px;
}
.container div > img {
  vertical-align: middle;
}
.box {
  border-radius: 10px;
  background: #fafafc;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.box:hover {
  background-color: #e0e0e0;
}

.open {
  background-color: #e0e0e0;
}

.openBox {
  padding: 0px 55px 10px 55px;
  border-radius: 10px;
  background: #fafafc;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transform-origin: top; /* Set the transform origin to the top to make it appear like it's expanding from the top */
  transform: scaleY(0); /* Initially set the scale to 0 to make it invisible */
  transform-origin: top;
  opacity: 0; /* Initially set opacity to 0 */
}
.openBox.active {
  transform: scaleY(1); /* Scale it to 1 to make it visible */
  opacity: 1; /* Set opacity to 1 */
}

.subopenBox {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  max-height: 300px;
  flex-wrap: wrap;
}

.activeTitle {
  color: #f3223c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.inactiveTitle {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
}
.subPageContainer{
   display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .container {
    padding: 12px;
  }
  .container div img {
    width: 15px;
    height: 15px;
  }
  .inactiveTitle,.activeTitle{
    font-size: 13px;
  }
}
