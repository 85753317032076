/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f8f8f802;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1b224c;
  border-radius: 25px;
}
