.login-container {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: "translate(-50%,-50%";
  z-index: 1000;
  width: 100%;
  height: 100vh;
}
.login-subContainer {
  position: relative;
  padding: 30px 50px;
  background-color: #ffffff;
  border-radius: 10px;
  height: 65vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  opacity: 0.9;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.login-sub-Container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 40vw;
  overflow: scroll;
}

.login-sub-Container .logo-login {
  align-self: center;
}
.logo-login {
  width: 30vw;
}
#background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/* @media (max-height: 700px) and (min-width: 1500px) {
  .login-subContainer {
    height: 90vh;
  }
} */

@media (min-width: 700px) and (max-height: 600px) {
  .login-subContainer {
    height: 95vh;
  }
}
@media screen and (max-width: 750px) {
  .login-subContainer {
    height: 70vh;
    width: 85vw;
  }
  .login-sub-Container {
    width: 75vw;
  }
  .logo-login {
    width: 60vw;
  }
}
