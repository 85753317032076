.tab-container {
  display: flex;
  flex-direction: row;
  position: relative;
}
.table-container {
  padding: 30px 20px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 8%);
  background-color: #fff;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  max-height: 86vh;
}
@media screen and (max-width: 1000px) {
  .table-container {
    padding: 15px 5px;
    height: 100vh;
  }
  .number {
    display: none;
  }
}