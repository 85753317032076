.campaign_titleContainer {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.campaign_titleContainer h2 {
  color: #1b224c;
  font-size: 16px;
  font-weight: 700;
}
.campaign_titleContainer h3 {
  color: #1b224c;
  font-size: 15px;
  font-weight: 500;
  /* opacity: 0.8; */
}

.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.notes {
  max-width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 1550px) and (max-height: 750px) {
 .container img{
  height: 20px;
 }
.container p{
  font-size: 18px !important;
}
}
@media screen and (max-width: 1450px) {
  .campaign_titleContainer h2 {
    font-size: 16px;
  }
  .campaign_titleContainer h3 {
    font-size: 14px;
  }
}
@media screen and (max-width: 1300px) {
  .campaign_titleContainer {
    /* flex-direction: column-reverse !important; */
    gap: 2px !important;
  }
}

@media screen and (max-width: 1000px) {
  .campaign_titleContainer h2 {
    font-size: 17px !important;
  }
  .campaign_titleContainer h3 {
    font-size: 15px !important;
  }
  .container p {
    font-size: 15px !important;
  }
  .container img {
    width: 16px !important;
  }
}

@media screen and (max-width: 730px) {
  .campaign_titleContainer h2 {
    font-size: 14px !important;
  }
  .campaign_titleContainer h3 {
    font-size: 12px !important;
  }
  .container p {
    font-size: 14px !important;
  }
  .container img {
    width: 16px !important;
  }
}
