.reference-container {
  background-color: #fff;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.reference {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}
.reference-subcontainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.document-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.document-subcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  padding: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.document {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.document-container h2 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.document h2 {
  font-size: 14px;
  font-weight: 600;
}
.document h4 {
  font-size: 12px;
  font-weight: 400;
}
.document p > img {
  vertical-align: middle;
}
@media screen and (max-width: 900px) {
  .reference {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .document-container {
    gap: 8px;
  }
  .document-container h2 {
    font-size: 13px;
  }
  .document-subcontainer {
    width: 80vw;
  }
}
