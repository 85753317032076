.table-container {
  width: 100%;
  border-collapse: collapse;
}

.table-container thead {
  position: sticky;
  top: -7px;
  z-index: 1;
}
.table-container thead th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.table-container thead th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.table-container th,
.table-container td {
  padding: 16px 15px;
  border: none;
  white-space: nowrap;
}
.container {
  max-height: calc(100vh - 325px);
  overflow-y: auto;
}
.table-container thead {
  background: #ecf4ff;
}
.table-container thead th {
  text-align: left;
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.table-container thead tr {
  border-radius: 10px !important;
}
.table-container thead th > img {
  vertical-align: middle;
}
/* Styling even rows with a light background color */
.table-container tr:nth-child(even) {
  border-radius: 10px;
  background-color: #fafafc;
}
.table-container tbody td {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #1b224c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
th > img {
  vertical-align: middle;
}
.filter-row {
  display: none;
  opacity: 0;
  transition: 1s ease-in-out;
}
.filter-row.open {
  display: contents;
  opacity: 1;
  transition: 1s ease-in-out;
}
/* Styling odd rows with a slightly darker background color */
tbody tr:hover {
  background-color: #e7ebf0;
}
tbody tr:nth-child(even):hover {
  background-color: #e7ebf0;
}

.table-container tbody td img:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
  cursor: pointer;
}

.tr-checkbox {
  border-radius: 15px 0 0 15px;
  position: relative;
}
.leftBorder {
  border-left: 8px solid #f3223c;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: 0.1s ease-in-out;
}
.dublicate {
  border-radius: 27px;
  padding: 3px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding: 5px 10px;
  line-height: normal;
}
.arrowIcon {
  padding-left: 2px;
  text-align: center;
  vertical-align: middle;
}
.arrowIcon:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.rotate-up {
  transform: rotate(180deg);
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.rotate-down {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.icon-cont-table {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
.icon-cont-table img {
  height: 20px;
}
.textInput_container {
  padding-top: 30px !important;
}
.recruitment_type_span {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.recruitment_type {
  border-radius: 4px;
  background-color: #f00;
  width: 94px;
  height: 20px;
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  padding: 5px 8px;
}
.recruitment_type h5 {
  color: #fff;
  font-size: 10px;
  font-weight: 700;
}

@media screen and (max-width: 1650px) {
  .table-container th,
  .table-container td {
    padding: 25px 10px;
  }
  .table-container thead th {
    font-size: 13px;
  }
  .table-container tbody td {
    font-size: 13px;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    max-height: 70vh;
  }
  .dublicate {
    font-size: 12px;
    padding: 2px;
  }
}
@media screen and (max-height: 700px) and (max-width: 1300px) {
  /* .container {
    overflow-y: hidden;
  } */
  .table-container thead th {
    font-size: 12px;
    padding: 15px;
  }
  .table-container tbody td {
    font-size: 12px;
    padding: 10px;
  }

  .dublicate {
    font-size: 10px;
  }
  .arrowIcon {
    height: 12px;
  }
  .table-container thead {
    position: sticky;
    top: -25px;
    z-index: 1;
  }
}
@media screen and (max-width: 800px) {
  .table-container thead th,
  .table-container td {
    padding: 15px;
  }
  .leftBorder {
    border-left: 4px solid #f3223c;
  }
  .recruitment_type_span {
    gap: 0px;
    flex-direction: column;
  }
  .recruitment_type {
    width: 62px;
    height: 16px;
    padding: 0px 6px;
  }
  .recruitment_type h5 {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
  }
  .recruitment_type img {
    display: none;
  }
  .arrowIcon {
    height: 15px;
  }
  .icon-cont-table img {
    height: 15px;
  }
}
