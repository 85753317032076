.icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  /* padding: 10px 10px 30px 10px; */
  justify-content: space-between;
}
.iconFilter-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.icon-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  gap: 1rem;
}
.mobile {
  display: none;
}
.circle-icon {
  padding-top: 2px;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  background-color: #a6b8d2;
}

/*******Filter*****/
.filter-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 15px;
}
.filterbutton-container {
  display: flex;
  gap: 0.5rem;
  flex: 1;
  justify-content: center;
}

/****Column visibility***/
.columnVisibility-cont {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
}
.columnVisibility-subcont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 20px;
  border-radius: 10px;
}
.columnVisibility-subcont:first-child {
  padding-top: 30px;
}
.columnVisibility-subcont-even {
  background-color: #fafafc;
}

.columnVisibility-subcont:hover p {
  color: #f3223c;
  background-color: transparent;
}
.circle_icon_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.circle_icon {
  padding-top: 2px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: black;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  font-weight: 500;
  background-color: #cceaff;
}
.multi_icon_cont {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}
@media screen and (max-width: 1350px) {
  .icon-container {
    gap: 1rem;
  }
  .icon-subcontainer {
    gap: 0.4rem;
  }
}

@media screen and (max-width: 1200px) {
  .icon-container img {
    width: 18px !important;
    height: 18px !important;
  }
  .icon-container {
    /* flex-wrap: nowrap; */
    gap: 1rem;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
  }
  .multi_icon_cont {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .icon-container {
    gap: 0.2rem;
  }
}
