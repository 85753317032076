.userstable-container {
  width: 100%;
  border-collapse: collapse;
}
.userstable-container thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.userstable-container th,
.userstable-container td {
  padding: 18px 15px;
  border: none;
  white-space: nowrap;
}
.container {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.userstable-container thead {
  border-radius: 0px;
  background: #ecf4ff;
}
.userstable-container thead th {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}
.userstable-container thead th > img {
  vertical-align: middle;
}
/* Styling even rows with a light background color */
.userstable-container tr:nth-child(even) {
  border-radius: 10px;
  background-color: #fafafc;
}
.userstable-container tbody td {
  text-align: center;
  color: #1b224c;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: left;
}

th > img {
  vertical-align: middle;
}
.header-content {
  display: flex;
  align-items: center;
  gap: 2px;
}
.icon-cont-table {
  display: flex;
  gap: 1rem;
}
.icon-cont-table img {
  height: 20px;
}
.userstable-container tbody td img:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
  cursor: pointer;
}

.tr-checkbox {
  border-radius: 15px 0 0 15px;
  position: relative;
}
.leftBorder {
  border-left: 8px solid #f3223c;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  transition: 0.1s ease-in-out;
  top: 0;
  left: 0;
  height: 100%;
}
.arrowIcon {
  padding-left: 2px;
  text-align: center;
  vertical-align: middle;
}
.arrowIcon:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.rotate-up {
  transform: rotate(180deg);
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.rotate-down {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
@media screen and (max-width: 1650px) {
  .userstable-container th,
  .userstable-container td {
    padding: 20px 10px;
  }
  .userstable-container thead th {
    font-size: 13px;
  }
  .userstable-container tbody td {
    font-size: 13px;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    max-height: 70vh;
  }
  .userstable-container th,
  .userstable-container td {
    padding: 10px;
  }
  .userstable-container tbody td {
    font-size: 10px;
    padding: 10px;
  }
}
