.iconContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.iconContainer img {
  height: 60px;
}
.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.titleContainer h3 {
  color: #21231e;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.titleContainer h5 {
  color: #21231e;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.gridTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  flex: 1;
  padding-top: 10px;
}

@media screen and (max-width: 1000px) {
  .gridTwo {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 800px) {

  .titleContainer h3 {
    font-size: 13px;
  }
  .titleContainer h5 {
    font-size: 14px;
  }
}
