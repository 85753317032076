.loader {
  border: 2px solid #1b224c;
  border-left-color: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
