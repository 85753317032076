.file-input {
  display: none;
}

.file-input-container-upl,
.file-input-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #414c1bbc;
  border-radius: 5px;
  cursor: pointer;
  height: 100px;
}
.file-input-container-upl {
  background-color: #020b581f;
}
.img-text-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.img-text-container p {
  /* max-width: 60%;  */
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
  color: #1b224c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.size-upload-dc p {
  color: #1b224c;
  text-align: right;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.close-document-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
}
.smallImage {
  height: 30px;
}
.bigImage {
  height: 70px;
}

.attachment_container {
  display: flex;
  gap: 0.5rem;
  border-radius: 10px;
  border: 1px solid #1b224c;
  align-items: center;
  min-height: 50px;
  justify-content: space-between;
  padding-right: 15px;
  width: 100%; 
  position: relative;;
}
.attachment {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.attachment label {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.attachment_subContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1; /* Allow this container to grow */
  width: 60%;
  flex-grow: 1;
  overflow-x: auto;
  white-space: nowrap;
}
.attachment_container label {
  color: #8d90a5;
  font-family: Raleway;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.attachment_fileContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;

}
.attachment_input {
  border-radius: 37px;
  background: #e6eefa;
  max-width: 150px;
  height: 38px;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  gap: 5px;
  padding: 0 5px;
}
.attachment_input span {
  color: #1b224c;
  font-size: 14px;
  font-weight: 500;
}
/* .attachment_input{
  display: none;
} */
.attachment_icon {
  padding-left: 15px;
}



@media screen and (max-width:800px){
  .attachment_container label {
    font-size: 13px;
  }
  .attachment_icon{
    padding-left: 10px;
  }
  .attachment_input img{
width: 13px;
  }
  .attachment_input span{
    font-size: 12px;
  }
  .attachment_fileContainer {
    max-width: 200px;
  }
}