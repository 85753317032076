.date_container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.date_container h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-height: 700px) and (max-width: 1300px) {
  .date_container h4 {
    font-size: 12px;
  }
  .date_container img {
    width: 14px;
  }
}
@media screen and (max-width: 800px) {
  .date_container {
    gap: 4px;
  }
  .date_container h4 {
    font-size: 12px;
  }
  .date_container img {
    width: 14px;
  }
}
