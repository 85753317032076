.receivedEmailList_container {
  width: 390px;
  background-color: white;
  border-radius: 10px;
  overflow: scroll;
  max-height:58vh;
  border-radius: 10px;
}

.received_List {
  position: relative;
}
.leftBorder {
  border-left: 8px solid #fff;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: 0.1s ease-in-out;
}
.activeLeftBorder {
  border-left: 8px solid #f3223c;
}
.List {
  padding: 15px 21px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid #f2f3f4;
  cursor: pointer;
}
.active {
  border-radius: 10px;
  background-color: #fafafc;
}
.List h2 {
  color: #1b224c;
  font-size: 16px;
  font-weight: 700;
}
.List h3 {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1b224c;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.8;
}
@media screen and (max-height: 700px) and (max-width: 1300px) {
  .List {
    padding: 10px 20px;
    gap: 5px;

  }.List h2 {
    font-size: 13px;
  }
  .List h3 {
    font-size: 13px;
    font-weight: 400;
    opacity: 0.8;
  }
}