.date-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.application_title {
  color: #1b224c;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.subclinton-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 20px;
}
.terms-container {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  padding: 5px 0px;
}
.terms-viewContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  padding: 5px 0px;
}
.terms-titleContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.terms-titleContainer h1 {
  color: #1b224c;
  font-size: 17px;
  font-weight: 600;
}
.terms-titleContainer p {
  color: #1b224c;
  font-size: 16px;
  font-weight: 400;
}
.buttonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

@media screen and (max-width: 1100px) {
  .date-container {
    grid-template-columns: 1fr;
    row-gap: 5px;
  }
}
@media screen and (max-width: 800px) {
  .terms-titleContainer h1 {
    font-size: 14px;
  }
  .terms-titleContainer p {
    font-size: 13px;
  }
  .application_title {
    font-size: 14px;
  }
}
