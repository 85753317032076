.navbar-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 67px;
  /* height: calc(100vh - 150px); */
  height: 83vh;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.029);
  transition: 0.8s all;
}
.open {
  width: 270px;
  transition: 0.8s all;
  justify-content: center;
}
.navlink-title {
  color: #1b224c;
  font-size: 14px;
  font-weight: 700;
  max-width: 0;
  opacity: 0;
  height: 0;
  overflow: hidden;
  white-space: nowrap;
  transition: max-width 0.1s ease-in-out, opacity 0.1s ease-in-out;
}
.icon {
  max-width: 20px;
  max-height: 30px;
  filter: brightness(0) saturate(100%) invert(16%) sepia(9%) saturate(3931%)
    hue-rotate(194deg) brightness(92%) contrast(97%);
}
.ul-cont li:hover .navlink-title {
  color: #f3223c;
}
.navlink-title-opened {
  max-width: 100%;
  height: 100%;
  opacity: 1;
}
.navbar-container ul {
  list-style: none;
  text-decoration: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}
a {
  text-decoration: none;
}
.navbar-container ul li {
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
}
.openlistItem {
  justify-content: center;
}
.navbar-container img {
  width: 23.696px;
  height: 23.103px;
}
.navbar-container li:hover img {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.listItem {
  padding: 13px 14px;
  background-color: #fafafc;
}
.listItem2 {
  padding: 13px 14px;
  background-color: #fff;
}
.attributes {
  padding: 8px 25px;
  background-color: #1b224c;
  font-size: 15px;
  font-weight: 500;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #fff;
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}

.navbar-container .navlink-title.activeListItem {
  color: #f3223c;
}
.navbar-container li.activeListItem img {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}

.listItem:hover,
.listItem2:hover {
  background-color: #e7ebf0;
}
.navbar-container li:hover {
  color: #f3223c;
}

.date-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.application_title {
  color: #1b224c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.subclinton-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 20px;
}
.terms-container {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  padding: 5px 10px;
}
@media screen and (max-height: 780px) and (max-width: 1550px) {
  .open {
    width: 200px;
  }
}
