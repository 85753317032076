.filter-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 15px;
  }
  .filterbutton-container {
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr ;
  gap:5px
  }
  
  @media screen and (max-width:800px){
   
  }