.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  left: -100px;
  background-color: #1b224c;
  overflow-x: hidden;
  transition: 0.4s;
  z-index: 1000;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.662);

  padding: 20px;
}

.sidebar.open {
  width: 40%;
  left: 0px;
}
.burgerImage {
  cursor: pointer;
}

.sidebar .closeBtn {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  top: 20px;
  right: 25px;
  font-size: 36px;
  color: #fff;
  padding-bottom: 50px;
}
.closeBtn {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .sidebar.open {
    width: 100%;
    left: 0px;
  }
}
