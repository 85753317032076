.email-content-cont {
  border-radius: 10px;
  border: 1px solid #1b224c;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.email-content{
  display: flex;
  flex-direction: column;
  gap:1rem
}
.email-content h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 5px;
}
.email-content h4 span {
  color: #f3223c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 5px;
}
.email-header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.email-header div {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.email-header img {
  font-size: 12px;
}
.down-arrow {
  width: 14px !important;
}

.hrStyle {
  height: 0.5px;
  background-color: #eeeeee;
  border: none;
}
.color-input {
  border: none;
  cursor: pointer;
  width: 28px;
  height: 28px;
}
