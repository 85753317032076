.onlinform-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sub-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}
.grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.grid-container div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.personal-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 26px;
  background-color: #fff;
}
.personalsub-container {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
@media screen and (max-width: 1300px) {
  .personalsub-container {
    flex-wrap: wrap;
  }
}
.personalsubds-container {
  max-width: 49%;
}
.personalsub-container div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.personal-container2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.onlinform-container h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.subtitle {
  color: #1b224c;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.filled-text,
.unfilled-text {
  font-family: Raleway;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}
.filled-text a {
  color: #1b224c !important;
  font-family: Raleway;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}
.filled-text a:hover {
  color: #f3223c !important;
}
p > img,
.unfilled-text > img {
  /* vertical-align:bottom; */
  width: 15px;
  height: 15px;
}

@media screen and (max-width: 1300px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1200px) {
  .sub-container {
    display: grid;
    /* grid-template-columns: 1fr; */
    gap: 2rem;
  }
  .personal-container h3 {
    font-size: 16px;
  }
  .personalsub-container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .personalsubds-container {
    max-width: 100%;
  }
}
@media screen and (max-width:800px){
  .sub-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .onlinform-container{
    gap:0.5rem
  }
}