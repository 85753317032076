.email_container {
  flex: 1;
  background-color: white;
  padding: 30px 28px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
  max-height: 50vh;
  overflow: scroll;
  max-height: 58vh;
  border-radius: 10px;
}
.email_container img {
  width: 45px;
}
.subContainer {
  gap: 10px;
  padding: 0px;
}
.header_subContainer {
  justify-content: space-between;
}
.email_header_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.email_header_container p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.email_container h3 {
  color: #1B224C;
  font-size: 14px;
  font-weight: 600;
}
.email_container h3 span {
  color: #1B224C;
  font-size: 16px;
  font-weight: 700;
}
.email_container h4 {
  color: #1B224C;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 4px;
}
@media screen and (max-height: 700px) and (max-width: 1300px) {
  .email_container {
    padding: 15px;
    gap: 5px;
  }

  .email_container h3,
  .email_header_container p {
    font-size: 12px;
  }

  .email_container h4 {
    font-size: 12px;
  }
}
